<template>
  <div>
    <div class="page-layout">
      <div class="container-title">Draw Product Roadmap</div>
      <div class="description">
        The Product Roadmap maps out the direction of the product development
        over time. Based on learnings from the design sprint, priority should be
        given to the most important ideas, features or user stories.
      </div>
      <div class="info-container">
        <div class="info-header">STUFF TO KNOW</div>
        <div class="item-list" v-for="(item, i) in listItems" :key="i">
          <div><img src="/img/onboarding/grey-checked.svg" /></div>
          <div>{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({
    listItems: [
      `The Product Roadmap would be drawn out by the Product
       team and shared with the Product Owner for Approval.`,
      `Collaborate with the Product Owner to make sure that the roadmap captures their vision`,
      `Once work starts, you would not be able to add new ideas,
        features/user stories to an ongoing iteration.
         Every new idea would be moved to the subsequent iterations.`,
      `Sprints are run weekly, so capture a week’s work in each sprint.`,
    ],
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
.page-layout {
  text-align: left;
}
.container-title {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.description {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 512px;
  margin-top: 16px;
}
.info-container {
  margin-top: 48px;
}
.info-header {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.item-list {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 12px;
  display: flex;
  width: 512px;
}
.item-list img {
  margin-right: 8px;
}
</style>
