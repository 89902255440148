var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-layout" }, [
        _c("div", { staticClass: "section-cover" }, [
          _c(
            "div",
            {
              staticClass: "alert alert-warning",
              staticStyle: { width: "540px" },
            },
            [_vm._v("You do not need to do anything at this stage")]
          ),
          _c("div", { staticClass: "schedule-meeting" }, [_vm._v("KickOff")]),
          _vm.project.preview_meeting_start_date == null
            ? _c(
                "div",
                {
                  staticClass: "meeting-info",
                  staticStyle: { width: "521px" },
                },
                [
                  _vm._v(
                    "\n\n        Wait for the product owner to complete the next steps and kickoff\n      "
                  ),
                ]
              )
            : _c("div", { staticClass: "meeting-info" }, [
                _vm._v(
                  "\n        Meeting has been scheduled and invites sent ot all team members added\n        to to this project.\n      "
                ),
              ]),
          _vm.project.preview_meeting_start_date == null
            ? _c("div", { staticClass: "calendar-embed" }, [_vm._m(0)])
            : _c("div", { staticClass: "calendar-embed" }, [
                _c("div", { staticClass: "meeting-details" }, [
                  _c("img", {
                    attrs: { src: "/img/onboarding/schedule-icon.svg" },
                  }),
                  _c("div", { staticClass: "meeting-time" }, [
                    _vm._v("\n            Meeting scheduled for\n            "),
                    _c("span", {}, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm
                              .$moment(_vm.project.preview_meeting_start_date)
                              .format("dddd, MMMM Do YYYY, h:mm:ss a")
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "meeting-link" }, [
                    _vm._v("\n            Meeting link:\n            "),
                    _c("span", [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: _vm.project.preview_meeting_hangout_link,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.project.preview_meeting_hangout_link) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
          _c("div", { staticClass: "bottom-div" }, [
            _c("div", { staticClass: "meeting-goal" }, [
              _c("div", { staticClass: "meeting-header" }, [
                _vm._v("THESE WILL HAPPEN NEXT"),
              ]),
              _c(
                "ol",
                { attrs: { type: "1" } },
                _vm._l(_vm.objectives, function (obj, i) {
                  return _c("li", { key: i, staticClass: "objectives" }, [
                    _vm._v("\n              " + _vm._s(obj) + "\n            "),
                  ])
                }),
                0
              ),
            ]),
            _vm.project.preview_meeting_start_date !== null
              ? _c(
                  "div",
                  { staticClass: "meeting-attendees" },
                  [
                    _c("div", { staticClass: "meeting-header" }, [
                      _vm._v(
                        "\n            MEETING INVITEES (" +
                          _vm._s(_vm.teamMembers.length + 1) +
                          ")\n          "
                      ),
                    ]),
                    _vm._l(_vm.teamMembers, function (member, i) {
                      return _c("div", { key: i, staticClass: "members-box" }, [
                        _c("div", { staticClass: "team-member" }, [
                          _c(
                            "div",
                            { staticClass: "profile-image" },
                            [
                              _c("v-avatar", {
                                staticClass: "profile_bg",
                                style: {
                                  backgroundImage:
                                    "url(" +
                                    (_vm.$imageUrl + member.profile_picture ||
                                      _vm.test) +
                                    ")",
                                },
                                attrs: { size: 32, color: "grey lighten-4" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "dev-details" }, [
                            _c("div", { staticClass: "dev-name" }, [
                              _vm._v(_vm._s(member.name)),
                            ]),
                            _c("div", { staticClass: "dev-role" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    member.team_lead == 1 ? "Team Lead/" : ""
                                  ) +
                                  "\n                  " +
                                  _vm._s(member.team_role) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    }),
                    _vm._m(1),
                    _c("div", { staticClass: "call-teammembers" }, [
                      _vm._v(
                        "\n            Reach out to your teammates to accept the invite\n          "
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.openDialog
        ? _c(
            "RateMeetingDialog",
            _vm._b(
              { attrs: { meetingTitle: "Client", form: _vm.ratingForm } },
              "RateMeetingDialog",
              {
                closeDialog: _vm.closeDialog,
                submitRating: _vm.submitRating,
                ratingSpinner: _vm.ratingSpinner,
                ratingSucess: _vm.ratingSucess,
                failForm: _vm.failForm,
                isMeetingHold: _vm.isMeetingHold,
                handleMeetingStatus: _vm.handleMeetingStatus,
                isRatingForm: _vm.isRatingForm,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "meeting-details" }, [
      _c("img", { attrs: { src: "/img/onboarding/schedule-icon.svg" } }),
      _c("div", { staticClass: "meeting-time" }, [
        _vm._v(
          "\n            Waiting for Product owner to proceed...\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "members-box" }, [
      _c("div", { staticClass: "team-member" }, [
        _c("div", { staticClass: "profile-image" }, [
          _c(
            "div",
            {
              staticClass: "grupa-icon user-name-icon",
              staticStyle: { height: "32px", width: "32px" },
            },
            [_vm._v("\n                  GR\n                ")]
          ),
        ]),
        _c("div", { staticClass: "dev-details" }, [
          _c("div", { staticClass: "dev-name" }, [_vm._v("Grupa")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }