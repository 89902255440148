<template>
  <div class="task-sprint-container" @click="removePrompt">
    <div class="task-assigned-header">
      <div class="task-assigned-title">TASKS ASSIGNED TO YOU</div>
      <div class="current-sprint">Sprint {{ ongoingSprint.sprint }}</div>
      <div class="total-assign-tasks">
        {{ completedTasks }}/{{ tasks.length }} Tasks Completed
      </div>
      <!-- <div class="progress-msg" @click="showTasks">
        You’re behind schedule, How can we help?
      </div> -->
    </div>

   
    <center
      v-if="tasks.length <= 0"
      style="padding-bottom: 150px; padding-top: 50px"
    >
      <div class="row select-team-row" style="">
        <div class="col-9">
          <div class="bold-text-head">Sprint is empty</div>
          <div class="light-text-head">
            Your sprint is empty. You need to add task to your sprint to
            continue.
          </div>
        </div>
        <!--  -->
        <div class="col-3">
          <div
            style="
              float: none;
              margin-top: 20px;
              margin-bottom: 0px;

              cursor: pointer;
            "
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="routeToRoadMap"
            class="btn grupa-blue-btn btn-lg"
          >
            Add Task to Sprint
          </div>
        </div>
      </div>
    </center>

    <VuePerfectScrollbar
      class="perfect-scroll-area"
      :settings="settings"
      v-if="tasks.length > 0"
    >
      <div class="task-stages">
        <div
          class="single-stage-div"
          v-for="(stage, j) in taskProgress"
          :key="j"
        >
          <div class="task-stage-name">
            <img :src="imgLink + stage.img" />
            <div>{{ stage.name }}</div>
          </div>
          <div class="stage-divider"></div>
          <div class="stage-tasks-list">
            <!--  -->
            <Container
              group-name="col"
              drag-class="card-ghost"
              drop-class="card-ghost-drop"
              :drop-placeholder="dropPlaceholderOptions"
              @drop="(e) => onCardDrop(stage.task_status, e)"
              :get-child-payload="getCardPayload(stage.task_status)"
            >
              <Draggable
                v-for="(task, k) in tasks"
                :key="k"
                v-if="
                  stage.task_status == task.task_status_id &&
                  stage.task_status != 3
                "
              >
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template slot="activator">
                    <div
                      class="stage-task-card"
                      @mousedown="updateMove($event, task)"
                      @click="handleShowPrompt(task, stage.task_status)"
                    >
                      <div class="task-text">
                        {{ task.task }}
                      </div>

                      <div class="card-task-icon">
                        <img
                          :src="`/img/dashboard/task/${taskType(
                            task.task_status_id
                          )}.svg`"
                        />
                      </div>
                    </div>
                    <!-- Prompt -->
                    <!-- v-if="isMoveTask" -->
                    <!-- -->
                  </template>
                  <MoveTaskPrompt
                    v-bind="{
                      currentTask,
                      handleMoveTask,
                      currentStage,
                      taskProgress,
                      spinner,
                    }"
                  />
                </v-menu>
              </Draggable>
              <Draggable
                v-for="(task, k) in tasks"
                :key="k"
                v-if="
                  stage.task_status == 3 &&
                  task.task_status_id <= 4 &&
                  task.task_status_id >= 3
                "
              >
                <div
                  class="stage-task-card"
                  @mousedown="updateMove($event, task)"
                >
                  <div class="task-text">
                    {{ task.task }}
                  </div>
                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                    offset-y
                    :close-on-content-click="false"
                  >
                    <template slot="activator">
                      <div
                        @click="handleShowPrompt(task, stage.task_status)"
                        class="card-task-icon"
                      >
                        <img
                          :src="`/img/dashboard/task/${taskType(
                            task.task_status_id
                          )}.svg`"
                        />
                      </div>
                    </template>
                    <!-- Prompt -->
                    <!-- v-if="isMoveTask" -->
                    <MoveTaskPrompt
                      v-bind="{
                        currentTask,
                        handleMoveTask,
                        currentStage,
                        taskProgress,
                        spinner,
                      }"
                    />
                  </v-menu>
                </div>
              </Draggable>
            </Container>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <MarkAsCompletedModal
      v-if="isOpenDialog"
      v-bind="{ closeDialog, form, markTaskAsCompleted, currentTask, spinner }"
    />
    <ReportIssuesModal v-if="isReportModal" v-bind="{ closeModal, report }" />
    <!-- Task Progress -->
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Container, Draggable } from "vue-smooth-dnd";
import computeTask from "@/mixins/computeTask";
import appMixin from "@/mixins/appMixin";
import { mapActions, mapState } from "vuex";
import { devLinkTask } from "@/api/team";
import MoveTaskPrompt from "@/components/workspace/moveTaskPrompt";
import MarkAsCompletedModal from "@/components/workspace/markAsCompletedModal";
import ReportIssuesModal from "@/components/workspace/reportIssuesModal";
export default {
  props: {},
  components: {
    MoveTaskPrompt,
    MarkAsCompletedModal,
    ReportIssuesModal,
    Container,
    Draggable,
    VuePerfectScrollbar,
  },
  mixins: [computeTask, appMixin],
  data: () => ({
    taskProgress: [
      { name: "Backlog", img: "task-status.svg", task_status: 1 },
      {
        name: "In Progress",
        img: "in-progress.svg",
        task_status: 2,
      },
      // {
      //   name: "Completed - For QA Test",
      //   img: "completed.svg",
      //   task_status: 3,
      // },
      {
        name: "Completed - For Review",
        img: "done.svg",
        task_status: 5,
      },
    ],
    imgLink: "/img/dashboard/task/",
    isMoveTask: false,
    currentTask: {},
    taskId: 0,
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    currentStage: 0,
    isOpenDialog: false,
    form: {
      url: "https://",
    },
    report: {
      issues: "https://",
    },
    isReportModal: false,
    completedId: 0,
    spinner: false,
    settings: {
      maxScrollbarLength: 60,
    },
  }),

  created() {
    console.log("ongoingSprint",this.ongoingSprint2)
  },
  mounted ()
  {

  },
  methods: {
    routeToRoadMap() {

       this.$router.push({
        name: "roadmap",
        params: { id: this.$route.params.id },
      });
    },
    ...mapActions("devDashboard", ["updateProjectTask"]),
    stayTop(event) {
      event.stopPropagation();
    },
    removePrompt() {
      this.isMoveTask = false;
    },
    handleShowPrompt(task, stage) {
      this.currentTask = task;
      this.currentStage = stage;
      this.taskId = task.id;
      this.isMoveTask = true;
    },
    updateMove(event, task) {
      this.currentTask = JSON.parse(JSON.stringify(task));
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.taskProgress.filter(
          (p) => p.task_status === this.currentTask.task_status_id
        )[0];
      };
    },
    handleMoveTask(task_status_id, status) {
      let { id, sprint_id } = this.currentTask;
      let data = {
        task_status_id,
        sprint_id,
        action_destination_id: task_status_id,
        action_performed_id: 0,
        date_timezone: this.currentDate(),
      };
      if (task_status_id == 5) {
        this.completedId = task_status_id;
        this.isOpenDialog = true;
      } else {
        this.spinner = true;
        let taskList = JSON.parse(JSON.stringify(this.allTasks));
        devLinkTask(id, data)
          .then((res) => {
            if (res.data && !status) {
              let taskIndex = taskList.findIndex((task) => task.id == id);
              taskList[taskIndex] = res.data.task;
              this.updateProjectTask(taskList);
              this.removePrompt();
            }
          })
          .catch((err) => {
            this.$developConsole(err);
          })
          .finally(() => {
            this.spinner = false;
          });
      }
    },
    onCardDrop(columnId, dropResult) {
      let taskList = JSON.parse(JSON.stringify(this.allTasks));

      if (dropResult.addedIndex !== null) {
        let newTask = this.currentTask;
        newTask.task_status_id = columnId;
        let taskIndex = taskList.findIndex((task) => task.id == newTask.id);
        taskList[taskIndex] = newTask;
        this.updateProjectTask(taskList);
        this.handleMoveTask(columnId, "Moved");
      }
    },
    closeDialog() {
      this.isOpenDialog = false;
    },
    closeModal() {
      this.isReportModal = false;
    },
    async markTaskAsCompleted() {
      let task_status_id = this.completedId;
      if (this.form.url !== "") {
        this.spinner = true;
        let { url } = this.form;
        let { id, sprint_id } = this.currentTask;
        let data = {
          task_status_id,
          action_performed_id: 3,
          sprint_id,
          demo_url: url,
          date_timezone: this.currentDate(),
          sprint_source_id: sprint_id,
        };
        let taskList = JSON.parse(JSON.stringify(this.allTasks));
        await devLinkTask(id, data)
          .then((res) => {
            if (res.data) {
              let taskIndex = taskList.findIndex((task) => task.id == id);
              taskList[taskIndex] = res.data.task;
              this.form.url = "https://";
              this.isOpenDialog = false;
              this.updateProjectTask(taskList);
              this.removePrompt();
            }
            this.spinner = false;
          })
          .catch((err) => {
            this.spinner = false;
            this.$developConsole(err);
          });
      }
    },
    showTasks() {},
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("devDashboard", ["selectedProjectDoc", "selectedProject"]),
    user() {
      return this.currentUser;
    },
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
    sprints() {
      return this.selectedProjectDoc.sprints.filter(
        (sprint) => sprint.iteration_id == this.currentIteration.id
      );
    },
    ongoingSprint() {
      return this.selectedProjectDoc.sprints.find(
        (sprint) => sprint.status == 1
      );
    },

     ongoingSprint2() {
      return this.selectedProjectDoc.sprints.find(
        (sprint) => sprint.iteration_id == this.currentIteration.id
      );
    },
    tasks() {
      //  &&
      //     task.teams.find((dev) => dev.dev_id == this.user.id)
      //  &&task.teams.length > 0
      return this.selectedProjectDoc.tasks.filter(
        (task) => {

          if (task.iteration_id == this.currentIteration.id &&

           ( task.sprint_id == this.ongoingSprint.id )) {
            return task;

          }
          else {
            if (task.iteration_id == this.currentIteration.id && task.sprint_id ===null)
            {
             // console.log(task)
              
             }

           /*  console.log('Task Iteration ID : ' + task.iteration_id)
            console.log(' current Iteration : ' + this.currentIteration.id)
            console.log(' Task Sprint : ' + task.sprint_id);
            console.log(' Current Sprint : '+this.ongoingSprint.id);
            console.log('');
            console.log('') */
            
            
          }

        })
    },
    completedTasks() {
      let tasks = this.tasks.filter(
        (task) =>

          task.task_status_id >= 4
      );
      return tasks.length;
    },
    allTasks() {
      return this.selectedProjectDoc.tasks;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.select-team-row {
  background-color: black;
  width: auto;
  margin: 40px;
  padding: 20px;
  margin-bottom: -10px;
}

.light-text-head {
  margin-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;

  text-align: left;
}
.bold-text-head {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #ffffff;
  /*  text-transform: uppercase; */
  text-align: left;
}
.task-sprint-container {
  margin-top: 32px;
  width: 100%;
}
.task-assigned-header {
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
  display: flex;
  text-align: left;
  width: 100%;
}
.task-assigned-title {
  margin: 11px 0px 11px 0px;
  padding: 7px 24px 7px 0px;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  border-right: 1px solid #e4e5e7;
}
.progress-msg,
.total-assign-tasks,
.current-sprint {
  display: flex;
  margin: 11px 0px 11px 0px;
  padding: 7px 24px 7px 24px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  border-right: 1px solid #e4e5e7;
}
.progress-msg {
  color: #de9221;
  border-right: 0px;
  cursor: pointer;
}
.task-stage-name img {
  height: 16px;
  width: 16px;
}
.stage-task-card {
  cursor: move;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}

.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.opacity-ghost-drop {
  opacity: 1;
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}
</style>
<style>
.stage-tasks-list .smooth-dnd-container {
  min-height: 500px;
}
</style>
