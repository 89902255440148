var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Toolbar"),
      _c("div", { staticClass: "pm-workspace" }, [
        _c(
          "div",
          { staticClass: "pm-container" },
          [
            _c(
              "div",
              { staticClass: "progress-report-wrapper" },
              [
                _c("ProjectReport"),
                _c(
                  "div",
                  { staticClass: "project-activity" },
                  [
                    _c(
                      "ActivityLog",
                      _vm._b(
                        {},
                        "ActivityLog",
                        {
                          projectActivity: _vm.projectActivity,
                          teamMembers: _vm.teamMembers,
                        },
                        false
                      )
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("center", [
              _c("div", { staticClass: "row select-team-row" }, [
                _c("div", { staticClass: "col-9" }, [
                  _c("div", { staticClass: "bold-text-head" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.IterationSprintStatus.iteration_id !==
                            _vm.currentIteration.id
                            ? "Start Next Iteration "
                            : _vm.currentSprint
                            ? _vm.currentSprint.start_date == "" ||
                              _vm.currentSprint.start_date == null
                              ? "Sprint not started"
                              : "Change Task Status"
                            : "Sprint not started"
                        ) +
                        "\n            "
                    ),
                  ]),
                  _c("div", { staticClass: "light-text-head" }, [
                    _vm._v(
                      "\n\n               " +
                        _vm._s(
                          _vm.IterationSprintStatus.iteration_id !==
                            _vm.currentIteration.id
                            ? "You are doing realy great. Kindly continue with your next iteration"
                            : _vm.currentSprint
                            ? _vm.currentSprint.start_date == "" ||
                              _vm.currentSprint.start_date == null
                              ? "You need to start your your next sprint. "
                              : "Update your task backlog status. This helps the product founder to be carried along"
                            : "You need to start your your next sprint."
                        ) +
                        "\n             \n            "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn grupa-blue-btn btn-lg proceedBtn",
                      staticStyle: {
                        float: "none",
                        "margin-top": "20px",
                        "margin-bottom": "0px",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          _vm.IterationSprintStatus.iteration_id !==
                          _vm.currentIteration.id
                            ? _vm.startNewIteration(
                                _vm.IterationSprintStatus.iteration_id
                              )
                            : _vm.currentSprint
                            ? _vm.currentSprint.start_date == "" ||
                              _vm.currentSprint.start_date == null
                              ? (_vm.isOpenDialog = true)
                              : _vm.openTaskReportModal()
                            : (_vm.isOpenDialog = true)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.IterationSprintStatus.iteration_id !==
                              _vm.currentIteration.id
                              ? "Start Iteration " +
                                  (this.currentIteration.it_number + 1)
                              : _vm.currentSprint
                              ? _vm.currentSprint.start_date == "" ||
                                _vm.currentSprint.start_date == null
                                ? "Start Sprint 1"
                                : " Update Backlog"
                              : "Start Sprint"
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "modal",
                attrs: {
                  id: "DeveloperTaskReport",
                  "data-target": ".bd-example-modal-lg",
                  tabindex: "-1",
                  "aria-labelledby": "DeveloperTaskReportLabel",
                  "aria-hidden": "true",
                },
              },
              [
                _c("div", { staticClass: "modal-dialog" }, [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        staticClass: "modal-body",
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "alert alert-warning",
                            staticStyle: {
                              "margin-left": "80px",
                              "margin-right": "80px",
                            },
                          },
                          [
                            _vm._v(
                              "\n              Updating your task by toggle between status based on your progress on  that task. You can also drag your each task to the appropriate status block\n              "
                            ),
                          ]
                        ),
                        _c("DeveloperTaskReport"),
                      ],
                      1
                    ),
                    _vm._m(1),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "container-divider" }),
          ],
          1
        ),
      ]),
      _vm.isOpenDialog
        ? _c(
            "StartSprint",
            _vm._b(
              {
                attrs: {
                  form: _vm.sprintForm,
                  closeDialog: _vm.closeStartModal,
                },
              },
              "StartSprint",
              {
                handleStartSprint: _vm.handleStartSprint,
                start_spinner: _vm.start_spinner,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v("UPDATE TASKS ASSIGNED TO YOU"),
      ]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light lightBorder",
          attrs: { type: "button", "data-bs-dismiss": "modal" },
        },
        [_vm._v("\n                Close\n              ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }