var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "task-sprint-container", on: { click: _vm.removePrompt } },
    [
      _c("div", { staticClass: "manage-task-header" }, [
        _c("div", { staticClass: "manage-task-title" }, [
          _vm._v("MANAGE TASKS"),
        ]),
        _c(
          "div",
          { staticClass: "sprint-list-div" },
          _vm._l(_vm.sprints, function (sprint, j) {
            return _c(
              "div",
              {
                key: j,
                staticClass: "sprint-box",
                class: { "current-sprint": _vm.currentSprint == sprint.sprint },
                on: {
                  click: function ($event) {
                    return _vm.switchSprint(sprint)
                  },
                },
              },
              [_vm._v("\n        Sprint " + _vm._s(sprint.sprint) + "\n      ")]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "task-break-down" },
        [
          _vm._l(_vm.taskAnalysis, function (task, i) {
            return _c("div", { key: i, staticClass: "task-group" }, [
              i == 0
                ? _c("div", { staticClass: "group-number" }, [
                    _vm._v(_vm._s(_vm.unAssignTasks.total)),
                  ])
                : _vm._e(),
              i == 1
                ? _c("div", { staticClass: "group-number" }, [
                    _vm._v(_vm._s(_vm.inDesignTasks.total)),
                  ])
                : _vm._e(),
              i == 2
                ? _c("div", { staticClass: "group-number" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.inDevelopmentTasks.total) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              i == 3
                ? _c("div", { staticClass: "group-number" }, [
                    _vm._v(_vm._s(_vm.inTestingTasks.total)),
                  ])
                : _vm._e(),
              i == 4
                ? _c("div", { staticClass: "group-number" }, [
                    _vm._v(_vm._s(_vm.completedTasks.total)),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "group-name" }, [
                _vm._v(_vm._s(task.name)),
              ]),
            ])
          }),
          _c("div", { staticClass: "sprint-due-date" }, [
            _c("div", { staticClass: "sprint-overdue-date" }, [
              _vm._v(_vm._s(_vm.sprintStartDate)),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "VuePerfectScrollbar",
        {
          staticClass: "perfect-scroll-area",
          attrs: { settings: _vm.settings },
        },
        [
          _c(
            "div",
            { staticClass: "task-stages" },
            _vm._l(_vm.taskStage, function (stage, j) {
              return _c("div", { key: j, staticClass: "single-stage-div" }, [
                _c("div", { staticClass: "task-stage-name" }, [
                  _c("img", { attrs: { src: _vm.imgLink + stage.img } }),
                  _c("div", [_vm._v(_vm._s(stage.name))]),
                ]),
                _c("div", { staticClass: "stage-divider" }),
                _c(
                  "div",
                  { staticClass: "stage-tasks-list" },
                  [
                    _vm._l(_vm.unAssignTasks.tasks, function (task, k) {
                      return j == 0
                        ? _c(
                            "div",
                            { key: k, staticClass: "stage-task-card" },
                            [
                              _c("div", { staticClass: "task-text" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(task.task) +
                                    "\n            "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "card-task-icon" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      staticClass: "user-story-menu",
                                      attrs: {
                                        bottom: "",
                                        origin: "center center",
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "activator" }, [
                                        _c("img", {
                                          attrs: {
                                            src: "/img/dashboard/task/unassign-task.svg",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleAssignTask(task)
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("AssignTaskPrompt", {
                                        on: { closePopup: _vm.closePopup },
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._l(_vm.inDesignTasks.tasks, function (task, k) {
                      return j == 1
                        ? _c(
                            "div",
                            { key: k, staticClass: "stage-task-card" },
                            [
                              _c("div", { staticClass: "task-text" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(task.task) +
                                    "\n            "
                                ),
                              ]),
                              _c("div", { staticClass: "card-task-icon" }, [
                                task.teams && task.teams.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "profile-icon" },
                                      _vm._l(
                                        _vm.teamMembers,
                                        function (member, i) {
                                          return member.id ==
                                            task.teams[task.teams.length - 1]
                                              .dev_id
                                            ? _c("v-avatar", {
                                                key: i,
                                                staticClass: "profile_bg",
                                                style: {
                                                  backgroundImage:
                                                    "url(" +
                                                    (_vm.$imageUrl +
                                                      member.profile_picture ||
                                                      _vm.test) +
                                                    ")",
                                                },
                                                attrs: {
                                                  size: 32,
                                                  color: "grey lighten-4",
                                                },
                                              })
                                            : _vm._e()
                                        }
                                      ),
                                      1
                                    )
                                  : _c("img", {
                                      attrs: {
                                        src: "/img/dashboard/task/unassign-task.svg",
                                      },
                                    }),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._l(_vm.inDevelopmentTasks.tasks, function (task, k) {
                      return j == 2
                        ? _c(
                            "div",
                            { key: k, staticClass: "stage-task-card" },
                            [
                              _c("div", { staticClass: "task-text" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(task.task) +
                                    "\n            "
                                ),
                              ]),
                              _c("div", { staticClass: "card-task-icon" }, [
                                task.teams && task.teams.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "profile-icon" },
                                      _vm._l(
                                        _vm.teamMembers,
                                        function (member, i) {
                                          return member.id ==
                                            task.teams[task.teams.length - 1]
                                              .dev_id
                                            ? _c("v-avatar", {
                                                key: i,
                                                staticClass: "profile_bg",
                                                style: {
                                                  backgroundImage:
                                                    "url(" +
                                                    (_vm.$imageUrl +
                                                      member.profile_picture ||
                                                      _vm.test) +
                                                    ")",
                                                },
                                                attrs: {
                                                  size: 32,
                                                  color: "grey lighten-4",
                                                },
                                              })
                                            : _vm._e()
                                        }
                                      ),
                                      1
                                    )
                                  : _c("img", {
                                      attrs: {
                                        src: "/img/dashboard/task/unassign-task.svg",
                                      },
                                    }),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._l(_vm.inTestingTasks.tasks, function (task, k) {
                      return j == 3
                        ? _c(
                            "div",
                            { key: k, staticClass: "stage-task-card" },
                            [
                              _c("div", { staticClass: "task-text" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(task.task) +
                                    "\n            "
                                ),
                              ]),
                              _c("div", { staticClass: "card-task-icon" }, [
                                task.teams && task.teams.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "profile-icon" },
                                      _vm._l(
                                        _vm.teamMembers,
                                        function (member, i) {
                                          return member.id ==
                                            task.teams[task.teams.length - 1]
                                              .dev_id
                                            ? _c("v-avatar", {
                                                key: i,
                                                staticClass: "profile_bg",
                                                style: {
                                                  backgroundImage:
                                                    "url(" +
                                                    (_vm.$imageUrl +
                                                      member.profile_picture ||
                                                      _vm.test) +
                                                    ")",
                                                },
                                                attrs: {
                                                  size: 32,
                                                  color: "grey lighten-4",
                                                },
                                              })
                                            : _vm._e()
                                        }
                                      ),
                                      1
                                    )
                                  : _c("img", {
                                      attrs: {
                                        src: "/img/dashboard/task/unassign-task.svg",
                                      },
                                    }),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._l(_vm.completedTasks.tasks, function (task, k) {
                      return j == 4
                        ? _c(
                            "div",
                            { key: k, staticClass: "stage-task-card" },
                            [
                              _c("div", { staticClass: "task-text" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(task.task) +
                                    "\n            "
                                ),
                              ]),
                              _c("div", { staticClass: "card-task-icon" }, [
                                task.teams && task.teams.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "profile-icon" },
                                      _vm._l(
                                        _vm.teamMembers,
                                        function (member, i) {
                                          return member.id ==
                                            task.teams[task.teams.length - 1]
                                              .dev_id
                                            ? _c("v-avatar", {
                                                key: i,
                                                staticClass: "profile_bg",
                                                style: {
                                                  backgroundImage:
                                                    "url(" +
                                                    (_vm.$imageUrl +
                                                      member.profile_picture ||
                                                      _vm.test) +
                                                    ")",
                                                },
                                                attrs: {
                                                  size: 32,
                                                  color: "grey lighten-4",
                                                },
                                              })
                                            : _vm._e()
                                        }
                                      ),
                                      1
                                    )
                                  : _c("img", {
                                      attrs: {
                                        src: "/img/dashboard/task/unassign-task.svg",
                                      },
                                    }),
                              ]),
                            ]
                          )
                        : _vm._e()
                    }),
                  ],
                  2
                ),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }