<template>
  <div>
    <Toolbar />
    <div class="pm-workspace">
      <div class="pm-container">
        <div class="progress-report-wrapper">
          <ProjectReport />
          <div class="project-activity">
            <ActivityLog v-bind="{ projectActivity, teamMembers }" />
          </div>
        </div>
        <TesterTasksReport />
        <div class="container-divider"></div>
        <ProjectDetails />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Toolbar from "@/components/toolbar/devProjectToolbar";
import ProjectReport from "@/components/workspace/projectReport";
import ActivityLog from "@/components/workspace/activityLog";
import ProjectDetails from "@/components/workspace/projectDetails";
import TesterTasksReport from "./testerTasksReport";
export default {
  props: {},
  components: {
    Toolbar,
    ProjectReport,
    ActivityLog,
    ProjectDetails,
    TesterTasksReport,
  },

  data: () => ({}),

  created() {},
  methods: {},
  computed: {
    ...mapState("devDashboard", ["projectActivity", "teamInfo"]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
</style>
