<template>
  <div>
    <div class="page-layout" @click="statusSelect = false">
      <div class="top-container">
        <div class="left-con">
          <div class="sprint-msg">
            Design Sprint is a week-long collaborative process with activites
            that ensures a product solves the problem form the users
            perspective, is technically feasible and meets business
            requirements. We have shared the schedule with the Product Owner
          </div>
          <div class="activity-msg">
            Each activity is key. Ensure the Product Owner (or a key decision
            maker) is present at every session as you collaborate to bring the
            idea to life.
          </div>
        </div>
        <div class="right-con">
          <div>
            <button class="grupa-grey-blue-btn" @click="viewSchedule">
              View Full Schedule
            </button>
          </div>
        </div>
      </div>
      <div class="sprint-schedule">
        <v-tabs
          v-model="active_tab"
          background-color="transparent"
          color="basil"
          grow
        >
          <v-tab
            v-for="(item, i) in sprintArray"
            :key="i"
            :disabled="active_tab !== i"
          >
            <div class="sprint-day">Day {{ item.day }}</div>
            <div class="sprint-date">
              {{ $moment(item.date).format("ddd, DD MMM") }}
            </div>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="active_tab">
          <v-tab-item v-for="(item, index) in sprintArray" :key="index">
            <div class="tab-content">
              <div class="sprint-activities">
                <div class="sprint-header">
                  {{ dailyActivities[index].title }}
                </div>
                <div class="text-below">
                  {{ dailyActivities[index].content }}
                </div>
                <div class="item-list">
                  Timeframe:
                  <span>3 - 4 hours</span>
                </div>
                <div class="item-list">
                  Deliverables:
                  <span>{{ deliverables }}</span>
                </div>
                <div class="item-list file-link">
                  Link to files:
                  <div>
                    <input
                      type="text"
                      style="margin-left: 8px"
                      class="no_outline"
                      :style="
                        link_to_file == ''
                          ? 'color: #C8CBD0;font-size: 14px;font-style: italic;font-weight: normal;'
                          : null
                      "
                      v-model="link_to_file"
                      placeholder="No link added yet"
                    />
                  </div>
                  <div><img src="/img/dashboard/edit-pen.svg" alt="" /></div>
                  <!-- <span>https://miro.com/grupa/boovers</span> -->
                </div>
                <div class="sprint-status-div">
                  <div class="item-list">Status:</div>
                  <div
                    class="menu-dropdown"
                    v-if="item.status_id == 0 && currentDesignSprint !== null"
                  >
                    <div
                      class="default-status"
                      @click="handleSelectStatus($event, index)"
                    >
                      <img src="/img/sprint/select-status.svg" />
                      <img
                        src="/img/dashboard/chevron-down.svg"
                        style="margin-top: 2px; margin-left: 3px"
                      />
                    </div>
                    <div
                      class="dropdown-items"
                      @click="stayTop($event)"
                      v-if="statusSelect && dayStatus == index"
                    >
                      <div
                        class="sprint-status-list"
                        @click="handleDailyStatus(1)"
                      >
                        <img src="/img/sprint/done.svg" />
                      </div>
                      <div
                        class="sprint-status-list"
                        @click="handleDailyStatus(2)"
                      >
                        <img src="/img/sprint/not-done.svg" />
                      </div>
                    </div>
                  </div>

                  <div
                    v-else
                    class="pick-dropdown"
                    @click="handleSelectStatus($event, index)"
                  >
                    <div
                      class="selected-status"
                      :class="{ 'sprint-not-done': item.status_id == 2 }"
                    >
                      <img
                        v-if="item.status_id == 1"
                        src="/img/sprint/done.svg"
                      />
                      <img
                        v-if="item.status_id == 2"
                        src="/img/sprint/not-done.svg"
                      />
                      <img src="/img/sprint/dropdown-angle.svg" />
                    </div>
                    <div
                      class="dropdown-items"
                      v-if="statusSelect && dayStatus == index"
                    >
                      <div
                        class="sprint-status-list"
                        v-if="item.status_id == 2"
                        @click="handleDailyStatus(1)"
                      >
                        <img src="/img/sprint/done.svg" />
                      </div>
                      <div
                        v-if="item.status_id == 1"
                        class="sprint-status-list"
                        @click="handleDailyStatus(2)"
                      >
                        <img src="/img/sprint/not-done.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="design-sprint-info">
                <div class="design-sprint-date">
                  <div><img src="/img/onboarding/schedule-icon.svg" /></div>
                  <div class="full-details">
                    Date:
                    <span>
                      {{ $moment(item.date).format("dddd DD/MMM/YYYY") }}</span
                    >
                  </div>
                  <div class="full-details">
                    Time:
                    <span
                      >{{
                        $moment(item.start_time, "HH:mm").format("hh:mm a")
                      }}
                      WAT</span
                    >
                  </div>
                  <div class="file-link full-details">
                    Link:
                    <span style="margin-left: 8px"
                      >https://goo.gl/hWrtygru</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <ActivityModal
      v-if="openDialog"
      v-bind="{
        closeDialog,
        dialogTitle,
        handleDailyStatus,
        currentDesignSprint,
        yesSpinner,
        noSpinner,
      }"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ActivityModal from "@/components/Modals/activityModal";
import { updateDesignSprint } from "@/api/team";
export default {
  props: {
    sprintArray: {
      type: Array,
    },
    updateSprintArray: {
      type: Function,
    },
  },
  components: {
    ActivityModal,
  },

  data: () => ({
    active_tab: 4,
    deliverables: `Personas, Competitive analysis, User journeys (the golden path).`,
    openDialog: false,
    dialogTitle: "Day 1 Activity",
    dailyActivities: [
      {
        title: `Understand the idea & the problem.`,
        content: `The Product Owner leads this session and explains the idea in detail to the Product Team. The team gets full understanding and asks general questions that would drive development and innovation.`,
      },
      {
        title: `Envision and ideate solutions.`,
        content: `Everyone works together, and individually to ideate, sketch and come up with as many solutions as possible. No solutions are chosen today. Just think as wildly as you can, sketching and exploring all possibilities.`,
      },
      {
        title: `Choose the best ideas.`,
        content: `The Product Owner filters the ideas from the previous day, and choose the one that has the best chance of achieving the product’s long-term goal. The team then puts together a storyboard with this idea as a guide for tomorrow’s prototyping.`,
      },
      {
        title: `Make a rough prototype.`,
        content: `The team works together, led by the Product Designer, to quickly come up with  low-fidelity prototypes that represent the solutions chosen the day before. The prototypes should be as close to the real thing as possible.`,
      },
      {
        title: `Test, validate & learn what works.`,
        content: `Today is all about learning with our customers. The designated teammate, preferrably the Product Designer, conducts one-on-one user interviews with the prototypes while the rest of the team watches on.`,
      },
    ],
    statusSelect: false,
    dayStatus: "",
    sprint_status: "",
    currentDesignSprint: null,
    noSpinner: false,
    yesSpinner: false,
    link_to_file: "",
  }),

  created() {
    this.getActiveDay();
  },
  methods: {
    ...mapActions("devPrekickoff", ["setDesignSprintDays"]),
    closeDialog() {
      this.openDialog = false;
    },
    viewSchedule() {
      let pdf = "/pdf/Grupa_design_sprint.pdf";
      window.open(pdf);
    },
    handleSelectStatus(event, index) {
      event.stopPropagation();
      this.statusSelect = true;
      this.dayStatus = index;
    },
    stayTop(event) {
      event.stopPropagation();
    },
    chooseSprintStatus(status) {
      this.sprint_status = status;
      this.statusSelect = true;
    },
    getActiveDay() {
      let spritDays = this.sprintArray;
      if (this.isDatePassed(spritDays[0].date) == false) {
        this.active_tab = 0;
        return spritDays[0];
      } else {
        let todayDate = spritDays.find(
          (spr) => this.isTodayDate(spr.date) == true
        );
        if (todayDate !== undefined) {
          this.active_tab = todayDate.day - 1;
          this.currentDesignSprint = todayDate;
          this.showActivityStatusModal();
          return todayDate;
        } else if (todayDate == undefined) {
          this.active_tab = 4;
          return spritDays[4];
        }
      }
    },
    isDatePassed(date) {
      return this.$moment(date).isBefore(this.$moment());
    },
    isTodayDate(date) {
      return this.$moment(date).isSame(new Date(), "day");
    },
    showActivityStatusModal() {
      let todayActive = this.currentDesignSprint;
      if (todayActive !== null && todayActive.status_id == 0) {
        let { date, start_time, day } = todayActive;
        let dateTime = `${date} ${start_time}`;
        if (this.isDatePassed(dateTime)) {
          this.openDialog = true;
          this.dialogTitle = `Day ${day} Activity`;
        }
      }
    },
    handleDailyStatus(status_id) {
      status_id == 1 ? (this.yesSpinner = true) : (this.noSpinner = true);
      let obj = { ...this.currentDesignSprint, status_id };
      let payload = JSON.parse(JSON.stringify(this.sprintArray));
      let sprintIndex = payload.findIndex((sprint) => sprint.day == obj.day);
      payload[sprintIndex] = obj;
      let formObj = {
        project_id: this.$route.params.id,
        design_sprints: payload,
      };
      updateDesignSprint(formObj)
        .then((resp) => {
          this.setDesignSprintDays(resp.data.data);
          this.updateSprintArray(resp.data.data);
          this.noSpinner = false;
          this.yesSpinner = false;
          this.openDialog = false;
          this.statusSelect = false;
        })
        .catch((error) => {});
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.top-container {
  display: flex;
  justify-content: space-between;
}
.left-con {
  width: 512px;
}
.sprint-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}

.activity-msg {
  font-size: 12px;
  line-height: 140%;
  color: #1fa564;
  margin-top: 16px;
}
.right-con {
  display: flex;
  justify-content: flex-end;
}
.sprint-schedule {
  margin-top: 48px;
}
.sprint-day {
  color: rgb(27, 30, 34, 0.4);
}
.sprint-date {
  margin: 4px 0px 12px 0px;
  color: rgb(151, 157, 165, 0.4);
}
.tab-content {
  margin-top: 24px;
  display: flex;
}
.sprint-activities {
  width: 521px;
  text-align: left;
}
.sprint-header {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.text-below {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 8px;
}
.full-details,
.item-list {
  margin-top: 24px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}

.full-details span,
.item-list span {
  color: #1b1e22;
}
.file-link {
  display: flex;
}
.file-link span {
  color: #0781f2;
}
.design-sprint-info {
  margin-left: 56px;
}
.design-sprint-date {
  background: #ffffff;
  border-radius: 4px;
  width: 260px;
  text-align: left;
  padding: 24px;
}
.sprint-status-div {
  display: flex;
  position: relative;
}
.dropdown-items {
  position: absolute;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
  z-index: 40;
  background-color: #ffffff;
  width: 119px;
}
.selected-status,
.menu-dropdown {
  margin-top: 24px;
  margin-left: 24px;
  cursor: pointer;
}
.selected-status {
  background: #e4e5e7;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
}
.activity-tract {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 356px;
  text-align: left;
}
.activity-tract span:first-child {
  color: #1fa564;
}
.activity-tract span:last-child {
  color: #a5271f;
}
.sprint-status-list {
  padding: 12px 16px;
  cursor: pointer;
  text-align: left;
  background: #ffffff;
}
.sprint-status-list:hover {
  background: #f7f7f8;
}
.sprint-not-done {
  background: rgba(212, 91, 84, 0.15);
}
.pick-dropdown {
  position: relative;
}
.pick-dropdown .dropdown-items {
  left: 25px;
}
.no_outline:focus {
  outline: none;
}
.default-status {
  background: #e4e5e7;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  cursor: pointer;
}

@media (max-width: 1440px) {
  .sprint-activities {
    width: 382px;
  }
}
@media (max-width: 1280px) {
  .sprint-activities {
    width: 382px;
  }
}
</style>


<style>
.sprint-schedule .v-tabs__item {
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}
.sprint-schedule .v-tabs__div {
  text-transform: initial;
}
.sprint-schedule .v-tabs__slider.accent {
  background-color: #1b1e22 !important;
  width: 121px;
}
.sprint-schedule .theme--light.v-tabs__bar {
  background-color: #f7f7f8;
}
.sprint-schedule .v-tabs__wrapper {
  border-bottom: 2px solid #e4e5e7;
}
.sprint-schedule .v-tabs__container {
  height: initial;
}
.sprint-schedule .v-tabs__item--active .sprint-day {
  color: #1b1e22;
}
.sprint-schedule .v-tabs__item--active .sprint-date {
  color: #979da5;
}

.menu-drop-down .v-menu__content.theme--light.menuable__content__active {
  top: 612px;
  left: 405px !important;
}
</style>

