<template>
  <div>
    <div class="vetting-progress">
      <div class="progress-wrapper">
        <div class="" v-for="(stage, k) in vettingStages" :key="k">
          <div v-if="k < 2">
            <div class="progress-stage">
              <img src="/img/onboarding/tick-icon.svg" alt="" />
              <div class="progress-name" style="color: #1b1e22">
                {{ stage }}
              </div>
            </div>
            <div
              class="progress-line"
              style="border-left: solid 3px #1b1e22"
            ></div>
          </div>
          <div v-if="k == 2 || k == 3">
            <div class="progress-stage">
              <img
                :src="`/img/onboarding/${
                  progressLevel <= k ? 'green-dot.svg' : 'tick-icon.svg'
                }`"
                alt=""
              />
              <!--  {{ progressLevel }} {{ k }} -->
              <div
                class="progress-name"
                :style="
                  progressLevel == k ||
                  progressLevel == 3 ||
                   (progressLevel == 5 && k == 3) ||
                  (progressLevel == 4 && k == 3)
                    ? 'color: #1fa564;'
                    : 'color: #1b1e22;'
                "
              >
                {{ k == 2 ? "KickOff" : k == 3 ? "DrawRoad Map" : "" }}

                <!-- {{ stage }} --><!--  {{ progressLevel }} -->
              </div>
            </div>
            <div
              class="progress-line"
              :style="
                progressLevel < k
                  ? 'border-left: solid 3px #54CF94;'
                  : 'border-left: solid 3px #1b1e22'
              "
            ></div>
          </div>
          <!--  <div v-if="k == 30">
            <div class="progress-stage">
              <img
                :src="`/img/onboarding/${
                  progressLevel == 3
                    ? 'green-dot.svg'
                    : progressLevel < 3
                    ? 'black-dot.svg'
                    : 'tick-icon.svg'
                }`"
                alt=""
              />
              <div
                class="progress-name"
                :style="
                  progressLevel == 3
                    ? 'color: #1fa564;'
                    : progressLevel > 3
                    ? 'color: #1b1e22;'
                    : ''
                "
              >
                {{ stage }}  {{ progressLevel }}
              </div>
            </div>
            <div
              class="progress-line"
              :style="
                progressLevel == 3
                  ? 'border-left: solid 3px #54CF94;'
                  : progressLevel > 3
                  ? 'border-left: solid 3px #1b1e22;'
                  : ''
              "
            ></div>
          </div>  -->
          <div v-if="k > 3">
            <div class="progress-stage">
              <img
                :src="`/img/onboarding/${
                  progressLevel == k
                    ? 'green-dot.svg'
                    : progressLevel < k
                    ? 'black-dot.svg'
                    : 'tick-icon.svg'
                }`"
                alt=""
              />
           <!--   {{ progressLevel }} {{ k }}  -->

              <div
                class="progress-name"
                :style="
                  progressLevel > k && progressLevel !=5
                    ? 'color: #1fa564;'
                    : progressLevel > 2
                    ? 'color: #1b1e22;'
                    : ''
                "
              >
                {{ stage }}
              </div>
            </div>
            <div
              v-if="k < 6"
              class="progress-line"
              :style="
                progressLevel == k
                  ? 'border-left: solid 3px #54CF94;'
                  : progressLevel > k
                  ? 'border-left: solid 3px #1b1e22;'
                  : ''
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      vettingStages: [
        "Accept Proposal",
        "Sign NDA",
        "KickOff",
        // "Run Design Sprint",
        "Draw Roadmap",
        "Finalise SLA",
        "Start Product Development",
      ],
      // progressLevel: 6,
    };
  },
  created() {},
  methods: {},
  computed: {
    ...mapState("devDashboard", ["selectedProject"]),
    project() {
      return this.selectedProject;
    },
    progressLevel() {
      return this.selectedProject.workspace_progress_level;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.vetting-progress {
  background: #ffffff;
  padding-top: 48px;
  display: flex;
  justify-content: center;
  width: 307px;
  height: 100%;
}
.progress-wrapper {
  position: fixed;
  height: 100%;
}
.progress-stage {
  display: flex;
}
.progress-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-left: 4px;
}
.progress-line {
  height: 60px;
  border-left: solid 3px #e4e5e7;
  margin: 7px 0px 10px 3px;
}
</style>
