<template>
  <div class="task-sprint-container" @click="removePrompt">
    <div class="manage-task-header">
      <div class="manage-task-title">MANAGE TASKS</div>
      <div class="sprint-list-div">
        <div
          class="sprint-box"
          @click="switchSprint(sprint)"
          :class="{ 'current-sprint': currentSprint == sprint.sprint }"
          v-for="(sprint, j) in sprints"
          :key="j"
        >
          Sprint {{ sprint.sprint }}
        </div>
      </div>
    </div>
    <div class="task-break-down">
      <div class="task-group" v-for="(task, i) in taskAnalysis" :key="i">
        <div v-if="i == 0" class="group-number">{{ unAssignTasks.total }}</div>
        <div v-if="i == 1" class="group-number">{{ inDesignTasks.total }}</div>
        <div v-if="i == 2" class="group-number">
          {{ inDevelopmentTasks.total }}
        </div>
        <div v-if="i == 3" class="group-number">{{ inTestingTasks.total }}</div>
        <div v-if="i == 4" class="group-number">{{ completedTasks.total }}</div>
        <div class="group-name">{{ task.name }}</div>
      </div>
      <div class="sprint-due-date">
        <div class="sprint-overdue-date">{{ sprintStartDate }}</div>
        <!-- <div><span>2 days overdue</span></div> -->
      </div>
    </div>
    <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
      <div class="task-stages">
        <div class="single-stage-div" v-for="(stage, j) in taskStage" :key="j">
          <div class="task-stage-name">
            <img :src="imgLink + stage.img" />
            <!-- <img src="/img/dashboard/task/task-status.svg" /> -->
            <div>{{ stage.name }}</div>
          </div>
          <div class="stage-divider"></div>
          <div class="stage-tasks-list">
            <!--  -->
            <div
              class="stage-task-card"
              v-if="j == 0"
              v-for="(task, k) in unAssignTasks.tasks"
              :key="k"
            >
              <div class="task-text">
                {{ task.task }}
              </div>
              <!-- @click="handleAssignTask($event, task.id)" -->
              <div class="card-task-icon">
                <v-menu
                  class="user-story-menu"
                  bottom
                  origin="center center"
                  transition="scale-transition"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template slot="activator">
                    <img
                      @click="handleAssignTask(task)"
                      src="/img/dashboard/task/unassign-task.svg"
                    />
                  </template>
                  <AssignTaskPrompt v-on:closePopup="closePopup" />
                </v-menu>
              </div>
              <!-- <AssignTaskPrompt
                v-if="currentTask == task.id && isAssignTask"
                v-bind="{
                  stayTop,
                  currentTask,
                  currentSprint,
                  removePrompt,
                }"
              /> -->
            </div>
            <!--  -->
            <div
              class="stage-task-card"
              v-if="j == 1"
              v-for="(task, k) in inDesignTasks.tasks"
              :key="k"
            >
              <div class="task-text">
                {{ task.task }}
              </div>
              <div class="card-task-icon">
                <div
                  class="profile-icon"
                  v-if="task.teams && task.teams.length > 0"
                >
                  <v-avatar
                    v-for="(member, i) in teamMembers"
                    :key="i"
                    v-if="member.id == task.teams[task.teams.length - 1].dev_id"
                    :size="32"
                    color="grey lighten-4"
                    class="profile_bg"
                    :style="{
                      backgroundImage: `url(${
                        $imageUrl + member.profile_picture || test
                      })`,
                    }"
                  ></v-avatar>
                </div>
                <img v-else src="/img/dashboard/task/unassign-task.svg" />
              </div>
            </div>
            <!--  -->
            <div
              class="stage-task-card"
              v-if="j == 2"
              v-for="(task, k) in inDevelopmentTasks.tasks"
              :key="k"
            >
              <div class="task-text">
                {{ task.task }}
              </div>
              <div class="card-task-icon">
                <div
                  class="profile-icon"
                  v-if="task.teams && task.teams.length > 0"
                >
                  <v-avatar
                    v-for="(member, i) in teamMembers"
                    :key="i"
                    v-if="member.id == task.teams[task.teams.length - 1].dev_id"
                    :size="32"
                    color="grey lighten-4"
                    class="profile_bg"
                    :style="{
                      backgroundImage: `url(${
                        $imageUrl + member.profile_picture || test
                      })`,
                    }"
                  ></v-avatar>
                </div>
                <img v-else src="/img/dashboard/task/unassign-task.svg" />
              </div>
            </div>
            <!--  -->
            <div
              class="stage-task-card"
              v-if="j == 3"
              v-for="(task, k) in inTestingTasks.tasks"
              :key="k"
            >
              <div class="task-text">
                {{ task.task }}
              </div>
              <div class="card-task-icon">
                <div
                  class="profile-icon"
                  v-if="task.teams && task.teams.length > 0"
                >
                  <v-avatar
                    v-for="(member, i) in teamMembers"
                    :key="i"
                    v-if="member.id == task.teams[task.teams.length - 1].dev_id"
                    :size="32"
                    color="grey lighten-4"
                    class="profile_bg"
                    :style="{
                      backgroundImage: `url(${
                        $imageUrl + member.profile_picture || test
                      })`,
                    }"
                  ></v-avatar>
                </div>
                <img v-else src="/img/dashboard/task/unassign-task.svg" />
              </div>
            </div>
            <!--  -->
            <div
              class="stage-task-card"
              v-if="j == 4"
              v-for="(task, k) in completedTasks.tasks"
              :key="k"
            >
              <div class="task-text">
                {{ task.task }}
              </div>
              <div class="card-task-icon">
                <div
                  class="profile-icon"
                  v-if="task.teams && task.teams.length > 0"
                >
                  <v-avatar
                    v-for="(member, i) in teamMembers"
                    :key="i"
                    v-if="member.id == task.teams[task.teams.length - 1].dev_id"
                    :size="32"
                    color="grey lighten-4"
                    class="profile_bg"
                    :style="{
                      backgroundImage: `url(${
                        $imageUrl + member.profile_picture || test
                      })`,
                    }"
                  ></v-avatar>
                </div>
                <img v-else src="/img/dashboard/task/unassign-task.svg" />
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import AssignTaskPrompt from "@/components/sprintPlanning/assignTaskToTeamMember";
import appMixin from "@/mixins/appMixin";

export default {
  mixins: [appMixin],
  props: {},
  components: {
    AssignTaskPrompt,
    VuePerfectScrollbar,
  },
  data: () => ({
    sprintID: 0,
    currentSprint: 1,
    taskAnalysis: [
      {
        value: 18,
        name: "Unassigned",
      },
      {
        value: 7,
        name: "In Design",
      },
      {
        value: 9,
        name: "In Development",
      },
      {
        value: 18,
        name: "In QA Test",
      },
      {
        value: 0,
        name: "Completed",
      },
    ],
    taskStage: [
      { name: "Unassigned", img: "task-status.svg" },
      { name: "Design", img: "design.svg" },
      { name: "Development", img: "development.svg" },
      { name: "Testing", img: "testing.svg" },
      { name: "Completed - For Review", img: "task-completed.svg" },
    ],
    imgLink: "/img/dashboard/task/",
    isAssignTask: false,
    currentTask: 0,
    currentSprint: 0,
    settings: {
      maxScrollbarLength: 60,
    },
    isAssignPopup: true,
  }),

  created() {
    this.setOngoingSprint();
  },
  methods: {
    ...mapActions("popupManagement", ["updateCurrentTask"]),
    switchSprint(sprint) {
      this.currentSprint = sprint.sprint;
      this.sprintID = sprint.id;
    },
    setOngoingSprint() {
      let { id, sprint } = this.ongoingSprint;
      this.sprintID = id;
      this.currentSprint = sprint;
    },
    stayTop(event) {
      event.stopPropagation();
    },
    removePrompt() {
      this.isAssignTask = false;
    },
    handleAssignTask(task) {
      this.isAssignPopup = true;
      this.updateCurrentTask(task);
    },
    closePopup() {
      this.isAssignPopup = false;
    },
  },
  computed: {
    ...mapState("devDashboard", [
      "selectedProjectDoc",
      "teamInfo",
      "selectedProject",
    ]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
    sprints() {
      // return this.selectedProjectDoc.sprints;
      return this.selectedProjectDoc.sprints.filter(
        (sprint) => sprint.iteration_id == this.currentIteration.id
      );
    },
    ongoingSprint() {
      let currentSprint = this.selectedProjectDoc.sprints.find(
        (sprint) => sprint.status == 1
      );
      return currentSprint !== undefined ? currentSprint : this.sprints[0];
    },
    tasks() {
      return this.selectedProjectDoc.tasks.filter(
        (task) =>
          task.iteration_id == this.currentIteration.id &&
          task.sprint_id == this.sprintID
      );
    },
    unAssignTasks() {
      let task = this.tasks.filter((task) => task.teams.length == 0);
      return {
        total: task.length,
        tasks: task,
      };
    },
    inDesignTasks() {
      let task = this.tasks.filter(
        (task) =>
          task.teams.length > 0 &&
          task.task_status_id != 5 &&
          task.teams[task.teams.length - 1].task_type_id == 2
      );
      return {
        total: task.length,
        tasks: task,
      };
    },
    inDevelopmentTasks() {
      let task = this.tasks.filter(
        (task) =>
          task.teams.length > 0 &&
          task.task_status_id != 5 &&
          task.teams[task.teams.length - 1].task_type_id == 1
      );
      return {
        total: task.length,
        tasks: task,
      };
    },
    inTestingTasks() {
      let task = this.tasks.filter(
        (task) =>
          task.teams.length > 0 &&
          task.task_status_id != 5 &&
          task.teams[task.teams.length - 1].task_type_id >= 3
      );
      return {
        total: task.length,
        tasks: task,
      };
    },
    sprintStartDate() {
      let sprint_id = this.sprintID;
      let currentSprint = this.sprints.find((spr) => spr.id == sprint_id);
      let { start_date } = currentSprint;
      if (start_date !== null) {
        return `${this.$moment(start_date).format("Do MMM")} -
          ${this.addDaysFromDate(start_date, 4)}`;
      } else {
        return "Not Started";
      }
    },
    completedTasks() {
      let task = this.tasks.filter((task) => task.task_status_id == 5);
      return {
        total: task.length,
        tasks: task,
      };
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.task-sprint-container {
  margin-top: 32px;
  width: 100%;
}
.manage-task-header {
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
  display: flex;
  text-align: left;
  width: 100%;
}
.manage-task-title {
  margin: 11px 0px 11px 0px;
  padding: 7px 24px 7px 0px;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  border-right: 1px solid #e4e5e7;
}
.sprint-list-div {
  margin-left: 24px;
  display: flex;
}
.sprint-list-div .sprint-box {
  padding: 18px 0px;
  margin-right: 40px;
  color: #979da5;
  cursor: pointer;
}
.sprint-list-div .current-sprint {
  color: #1b1e22;
  border-bottom: 1px solid #1b1e22;
}
.task-break-down {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}
.task-group {
  height: 85px;
  padding: 16px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 48px;
}
.group-number {
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.group-name {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 8px;
}
.sprint-due-date {
  width: 252px;
  padding: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 48px;
}
.sprint-overdue-date {
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.sprint-due-date span {
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #de9221;
  margin-top: 8px;
}
</style>
