<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">Edit Schedule</div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="form-grouped" v-for="(sprint, k) in form" :key="k">
          <div class="form-container">
            <div class="div-label">Day {{ sprint.day }}</div>
            <!-- v-menu date-picker -->
            <v-menu
              :ref="'element' + k"
              v-model="form[k]['dateMenu']"
              :close-on-content-click="false"
              :return-value.sync="form[k]['date']"
            >
              <div class="input_wrap" slot="activator">
                <input
                  type="text"
                  v-model="form[k]['date']"
                  class="date_textbox"
                  :placeholder="k == 0 ? 'Select date' : '<autofill>'"
                  @input="form_errors[k]['date'] = ''"
                />
                <img src="/img/dashboard/calendar-icon.svg" />
              </div>
              <v-date-picker
                v-model="form[k]['date']"
                no-title
                scrollable
                :min="todayDate"
                type="date"
                :disabled-dates="disabledDates"
                @update:picker-date="pickerUpdate($event)"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="form[k]['dateMenu'] = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs['element' + k][0].save(form[k]['date'])"
                >
                  Ok
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <div class="form-container">
            <div style="height: 19px"></div>
            <!-- v-menu time-picker -->
            <v-menu
              v-if="sprint.is_all_day == 0"
              :ref="'time' + k"
              v-model="form[k]['timeMenu']"
              :close-on-content-click="false"
              :return-value.sync="form[k]['start_time']"
            >
              <div class="input_wrap" slot="activator">
                <input
                  type="text"
                  v-model="form[k]['start_time']"
                  class="date_textbox"
                  :placeholder="k == 0 ? 'Select time' : '<autofill>'"
                  @input="form_errors[k]['start_time'] = ''"
                />
                <img src="/img/dashboard/time-icon.svg" />
              </div>
              <v-time-picker
                v-model="form[k]['start_time']"
                :landscape="true"
                :ampm-in-title="true"
                format="ampm"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="form[k]['timeMenu'] = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs['time' + k][0].save(form[k]['start_time'])"
                >
                  Ok
                </v-btn>
              </v-time-picker>
            </v-menu>
            <div v-else class="input_wrap" style="background: #f7f7f8">
              <input
                type="text"
                class="date_textbox"
                placeholder="All day"
                disabled="disabled"
              />
              <img src="/img/dashboard/time-icon.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <button class="grupa-grey-btn" style="margin-right: 24px">
          Cancel
        </button>
        <button class="grupa-blue-btn" @click="handleEditDesignSprint">
          Update Schedule
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="editSpinner"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    updateDesignSprintSchedule: {
      type: Function,
      required: true,
    },
    editSpinner: {
      type: Boolean,
    },
  },
  data() {
    return {
      todayDate: new Date().toISOString().slice(0, 10),
      repeat_time: false,
      form_errors: [
        { date: "", start_time: "" },
        { date: "", start_time: "" },
        { date: "", start_time: "" },
        { date: "", start_time: "" },
        { date: "", start_time: "" },
      ],
      form: [],
      disabledDates: { weekdays: [1, 7] },
      availableDates: [],
    };
  },
  created() {
    this.getEditDays();
  },
  watch: {
    watchFirstDate: function (newVal) {
      this.autoFillDays(newVal);
    },
  },
  methods: {
    getEditDays() {
      let sprintArray = this.designSprintDays;
      let sprintDays = sprintArray.map((sprint, index) => {
        // !this.isDatePassed(sprint.date) &&
        if (!this.isDatePassed(sprint.date) && index < 5) {
          let obj = {
            ...sprint,
            dateMenu: false,
            timeMenu: false,
          };
          return obj;
        }
      });
      this.form = sprintDays.filter((obj) => obj !== undefined);
    },
    isDatePassed(data) {
      return this.$moment(data).isBefore(this.$moment());
    },
    autoFillDays(selectedDate) {
      const day = this.$moment(selectedDate).isoWeekday();
      if (day > 5) {
        this.setErrorMsg(
          "Design sprint days must fall between Monday and Friday"
        );
        this.form[0].date = "";
      } else if (day <= 5) {
        this.form.forEach((date, i) => {
          if (i > 0) {
            let previousDate = this.form[i - 1].date;
            if (this.$moment(previousDate).isoWeekday() == 5) {
              let new_date = this.$moment(previousDate, "YYYY-MM-DD").add(
                3,
                "days"
              );
              date.date = new_date.format("YYYY-MM-DD");
            } else if (this.$moment(previousDate).isoWeekday() < 5) {
              let new_date = this.$moment(previousDate, "YYYY-MM-DD").add(
                1,
                "days"
              );
              date.date = new_date.format("YYYY-MM-DD");
            }
          }
        });
      }
    },
    allowedDates(a) {
      return this.availableDates.includes(a);
    },
    pickerUpdate(val) {
      let totalDay = this.$moment(val, "YYYY-MM").daysInMonth();
      let availableDates = [];

      let monthNow = this.$moment().format("M");
      let monthSelected = this.$moment(val).format("M");
      let day;

      if (monthNow == monthSelected) day = this.$moment().format("D");
      else day = 1;

      for (let i = day; i <= totalDay; i++) {
        let date = this.$moment()
          .month(val.split("-")[1] - 1)
          .date(i)
          .format("YYYY-MM-DD");
        if (this.$moment(date).day() !== 0) availableDates.push(date);
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
    handleEditDesignSprint() {
      let designSprintForm = this.form;
      let ErrorForm = [];
      // Validate textboxes
      for (let k = 0; k < designSprintForm.length; k++) {
        if (
          designSprintForm[k].date == "" ||
          designSprintForm[k].start_time == ""
        ) {
          ErrorForm.push(k);
        }
      }
      if (ErrorForm.length > 0) {
        this.setErrorMsg("Kindly fill all Design Sprint dates and start time");
      } else if (ErrorForm.length == 0) {
        let formObj = {
          project_id: this.$route.params.id,
          design_sprints: this.form,
        };
        this.updateDesignSprintSchedule(formObj);
      }

      let formObj = {
        project_id: this.$route.params.id,
        design_sprints: this.form,
      };
      this.updateDesignSprintSchedule(formObj);
    },
  },
  computed: {
    ...mapState("devPrekickoff", ["designSprintDays"]),
    ...mapActions("alertMsg", ["setErrorMsg"]),

    watchFirstDate() {
      if (this.form.length > 0) {
        return this.form[0].date;
      }
    },
  },
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
  width: 522px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}
/*  */
.dialog-content .form-grouped:first-child {
  margin-top: 0px;
}
.form-grouped {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}
.form-control {
  margin-top: 7px;
  margin-bottom: 0px;
  width: 225px;
  height: 48px;
}
.div-label {
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  text-align: left;
}
.date_textbox {
  width: 84%;
}
.input_wrap {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 0px;
  display: flex;
  padding: 15px 0px 12px 16px;
  height: 48px;
  width: 225px;
}
.date_textbox:focus {
  border: 0px;
  outline: none;
}
</style>


