<template>
  <div class="task-sprint-container" @click="removePrompt">
    <div class="task-assigned-header">
      <div class="task-assigned-title">TASKS ASSIGNED TO YOU</div>
      <div class="current-sprint">Sprint {{ ongoingSprint.sprint }}</div>
      <div class="total-assign-tasks">
        {{ completedTasks }}/{{ tasks.length }} Tasks Completed
      </div>
      <!-- <div class="progress-msg" @click="isReportModal = true">
        You’re behind schedule, How can we help?
      </div> -->
    </div>
    <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
      <div class="task-stages">
        <div
          class="single-stage-div"
          v-for="(stage, j) in taskProgress"
          :key="j"
        >
          <div class="task-stage-name">
            <img :src="imgLink + stage.img" />
            <div>{{ stage.name }}</div>
          </div>
          <div class="stage-divider"></div>
          <div class="stage-tasks-list">
            <!--  -->
            <Container
              group-name="col"
              drag-class="card-ghost"
              drop-class="card-ghost-drop"
              :drop-placeholder="dropPlaceholderOptions"
              @drop="(e) => onCardDrop(stage.task_status, e)"
            >
              <Draggable
                v-for="(task, k) in tasks"
                :key="k"
                v-if="stage.task_status == task.task_status_id"
              >
                <div
                  class="stage-task-card"
                  @mousedown="updateMove($event, task)"
                >
                  <div class="task-text">
                    {{ task.task }}
                  </div>
                  <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                    offset-y
                    :close-on-content-click="false"
                  >
                    <template slot="activator">
                      <div
                        @click="handleShowPrompt(task, stage.task_status)"
                        class="card-task-icon"
                      >
                        <img
                          :src="`/img/dashboard/task/${taskType(
                            task.task_status_id
                          )}.svg`"
                        />
                      </div>
                    </template>
                    <!-- Prompt -->
                    <MoveTaskPrompt
                      v-bind="{
                        currentTask,
                        handleMoveTask,
                        currentStage,
                        taskProgress,
                        spinner,
                      }"
                    />
                  </v-menu>
                </div>
              </Draggable>
            </Container>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <MarkAsCompletedModal
      v-if="isOpenDialog"
      v-bind="{ closeDialog, form, markTaskAsCompleted, currentTask, spinner }"
    />
    <ReportIssuesModal v-if="isReportModal" v-bind="{ closeModal, report }" />
    <!-- Task Progress -->
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Container, Draggable } from "vue-smooth-dnd";
import computeTask from "@/mixins/computeTask";
import appMixin from "@/mixins/appMixin";
import { mapActions, mapState } from "vuex";
import { devLinkTask } from "@/api/team";
import MoveTaskPrompt from "@/components/workspace/moveTaskPrompt";
import MarkAsCompletedModal from "@/components/workspace/markAsCompletedModal";
import ReportIssuesModal from "@/components/workspace/reportIssuesModal";
export default {
  props: {},
  components: {
    MoveTaskPrompt,
    MarkAsCompletedModal,
    ReportIssuesModal,
    Container,
    Draggable,
    VuePerfectScrollbar,
  },
  mixins: [computeTask, appMixin],
  data: () => ({
    taskProgress: [
      { name: "Backlog", img: "task-status.svg", task_status: 1 },
      { name: "In Progress", img: "in-progress.svg", task_status: 2 },
      { name: "Completed - For Review", img: "done.svg", task_status: 5 },
    ],
    imgLink: "/img/dashboard/task/",
    isMoveTask: false,
    currentTask: {},
    taskId: 0,
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    currentStage: 0,
    isOpenDialog: false,
    form: {
      url: "https://",
    },
    report: {
      issues: "https://",
    },
    isReportModal: false,
    completedId: 0,
    spinner: false,
    settings: {
      maxScrollbarLength: 60,
    },
  }),

  created() {},
  methods: {
    ...mapActions("devDashboard", ["updateProjectTask"]),
    stayTop(event) {
      event.stopPropagation();
    },
    removePrompt() {
      this.isMoveTask = false;
    },
    handleShowPrompt(task, stage) {
      this.currentTask = task;
      this.currentStage = stage;
      this.taskId = task.id;
      this.isMoveTask = true;
    },
    updateMove(event, task) {
      this.currentTask = JSON.parse(JSON.stringify(task));
    },
    async handleMoveTask(task_status_id, status) {
      let { id, sprint_id } = this.currentTask;
      let data = {
        task_status_id,
        sprint_id,
        action_performed_id: 0,
        date_timezone: this.currentDate(),
      };
      if (task_status_id == 5) {
        this.completedId = task_status_id;
        this.isOpenDialog = true;
      } else {
        this.spinner = true;
        let taskList = JSON.parse(JSON.stringify(this.allTasks));
        await devLinkTask(id, data)
          .then((res) => {
            if (res.data && !status) {
              let taskIndex = taskList.findIndex((task) => task.id == id);
              taskList[taskIndex] = res.data.task;
              this.updateProjectTask(taskList);
              this.removePrompt();
            }
          })
          .catch((err) => {
            this.$developConsole(err);
          })
          .finally(() => {
            this.spinner = false;
          });
      }
    },
    onCardDrop(columnId, dropResult) {
      let taskList = JSON.parse(JSON.stringify(this.allTasks));
      if (dropResult.addedIndex) {
        let newTask = this.currentTask;
        newTask.task_status_id = columnId;
        let taskIndex = taskList.findIndex((task) => task.id == newTask.id);
        taskList[taskIndex] = newTask;
        this.updateProjectTask(taskList);
        this.handleMoveTask(columnId, "Moved");
      }
    },
    closeDialog() {
      this.isOpenDialog = false;
    },
    closeModal() {
      this.isReportModal = false;
    },
    async markTaskAsCompleted() {
      let task_status_id = this.completedId;
      if (this.form.url !== "") {
        this.spinner = true;
        let { url } = this.form;
        let { id, sprint_id } = this.currentTask;
        let data = {
          task_status_id,
          action_performed_id: 3,
          sprint_id,
          demo_url: url,
          date_timezone: this.currentDate(),
          sprint_source_id: sprint_id,
        };
        let taskList = JSON.parse(JSON.stringify(this.allTasks));
        await devLinkTask(id, data)
          .then((res) => {
            if (res.data) {
              let taskIndex = taskList.findIndex((task) => task.id == id);
              taskList[taskIndex] = res.data.task;
              this.form.url = "https://";
              this.isOpenDialog = false;
              this.updateProjectTask(taskList);
              this.removePrompt();
            }
            this.spinner = false;
          })
          .catch((err) => {
            this.spinner = false;
            this.$developConsole(err);
          });
      }
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("devDashboard", ["selectedProjectDoc", "selectedProject"]),
    user() {
      return this.currentUser;
    },
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
    sprints() {
      return this.selectedProjectDoc.sprints.filter(
        (sprint) => sprint.iteration_id == this.currentIteration.id
      );
    },
    ongoingSprint() {
      return this.selectedProjectDoc.sprints.find(
        (sprint) => sprint.status == 1
      );
    },
    tasks() {
      return this.selectedProjectDoc.tasks.filter(
        (task) =>
          task.iteration_id == this.currentIteration.id &&
          task.sprint_id == this.ongoingSprint.id &&
          task.teams.length > 0 &&
          task.teams.find((dev) => dev.dev_id == this.user.id)
      );
    },
    completedTasks() {
      let tasks = this.tasks.filter(
        (task) => task.teams.length > 0 && task.task_status_id == 5
      );
      return tasks.length;
    },
    allTasks() {
      return this.selectedProjectDoc.tasks;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.task-sprint-container {
  margin-top: 32px;
  width: 100%;
}
.task-assigned-header {
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
  display: flex;
  text-align: left;
  width: 100%;
}
.task-assigned-title {
  margin: 11px 0px 11px 0px;
  padding: 7px 24px 7px 0px;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  border-right: 1px solid #e4e5e7;
}
.progress-msg,
.total-assign-tasks,
.current-sprint {
  display: flex;
  margin: 11px 0px 11px 0px;
  padding: 7px 24px 7px 24px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  border-right: 1px solid #e4e5e7;
}
.progress-msg {
  color: #de9221;
  border-right: 0px;
  cursor: pointer;
}
.task-stage-name img {
  height: 16px;
  width: 16px;
}
.stage-task-card {
  cursor: move;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}

.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.opacity-ghost-drop {
  opacity: 1;
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}
</style>
