var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "page-wrap-container" }, [
        _c("div", { staticClass: "start-prod-dev" }, [
          _vm._v("Start Product Development"),
        ]),
        _c("div", { staticClass: "text-msg-below" }, [
          _vm._v(
            "\n      You will be notified once the Product Owner selects and commits to a\n      kickoff date.\n    "
          ),
        ]),
        _c("div", { staticClass: "waiting-div-wrap" }, [
          _c("div", { staticClass: "waiting-client" }, [
            _c("img", { attrs: { src: "/img/onboarding/schedule-icon.svg" } }),
            _c("div", { staticClass: "center-text-div" }, [
              _vm._v(
                "\n          Waiting for the Product Owner to pay and commit to a kickoff date...\n        "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }