var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "task-sprint-container", on: { click: _vm.removePrompt } },
    [
      _c("div", { staticClass: "task-assigned-header" }, [
        _c("div", { staticClass: "task-assigned-title" }, [
          _vm._v("TASKS ASSIGNED TO YOU"),
        ]),
        _c("div", { staticClass: "current-sprint" }, [
          _vm._v("Sprint " + _vm._s(_vm.ongoingSprint.sprint)),
        ]),
        _c("div", { staticClass: "total-assign-tasks" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.completedTasks) +
              "/" +
              _vm._s(_vm.tasks.length) +
              " Tasks Completed\n    "
          ),
        ]),
      ]),
      _c(
        "VuePerfectScrollbar",
        {
          staticClass: "perfect-scroll-area",
          attrs: { settings: _vm.settings },
        },
        [
          _c(
            "div",
            { staticClass: "task-stages" },
            _vm._l(_vm.taskProgress, function (stage, j) {
              return _c("div", { key: j, staticClass: "single-stage-div" }, [
                _c("div", { staticClass: "task-stage-name" }, [
                  _c("img", { attrs: { src: _vm.imgLink + stage.img } }),
                  _c("div", [_vm._v(_vm._s(stage.name))]),
                ]),
                _c("div", { staticClass: "stage-divider" }),
                _c(
                  "div",
                  { staticClass: "stage-tasks-list" },
                  [
                    _c(
                      "Container",
                      {
                        attrs: {
                          "group-name": "col",
                          "drag-class": "card-ghost",
                          "drop-class": "card-ghost-drop",
                          "drop-placeholder": _vm.dropPlaceholderOptions,
                        },
                        on: {
                          drop: function (e) {
                            return _vm.onCardDrop(stage.task_status, e)
                          },
                        },
                      },
                      _vm._l(_vm.tasks, function (task, k) {
                        return stage.task_status == task.task_status_id
                          ? _c("Draggable", { key: k }, [
                              _c(
                                "div",
                                {
                                  staticClass: "stage-task-card",
                                  on: {
                                    mousedown: function ($event) {
                                      return _vm.updateMove($event, task)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "task-text" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(task.task) +
                                        "\n                "
                                    ),
                                  ]),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        bottom: "",
                                        origin: "center center",
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "close-on-content-click": false,
                                      },
                                    },
                                    [
                                      _c("template", { slot: "activator" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "card-task-icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleShowPrompt(
                                                  task,
                                                  stage.task_status
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  "/img/dashboard/task/" +
                                                  _vm.taskType(
                                                    task.task_status_id
                                                  ) +
                                                  ".svg",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "MoveTaskPrompt",
                                        _vm._b(
                                          {},
                                          "MoveTaskPrompt",
                                          {
                                            currentTask: _vm.currentTask,
                                            handleMoveTask: _vm.handleMoveTask,
                                            spinner: _vm.spinner,
                                            currentStage: _vm.currentStage,
                                            taskProgress: _vm.taskProgress,
                                          },
                                          false
                                        )
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e()
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm.isOpenDialog
        ? _c(
            "MarkAsCompletedModal",
            _vm._b(
              {},
              "MarkAsCompletedModal",
              {
                closeDialog: _vm.closeDialog,
                form: _vm.form,
                markTaskAsCompleted: _vm.markTaskAsCompleted,
                currentTask: _vm.currentTask,
                spinner: _vm.spinner,
              },
              false
            )
          )
        : _vm._e(),
      _vm.isReportModal
        ? _c(
            "ReportIssuesModal",
            _vm._b(
              {},
              "ReportIssuesModal",
              { closeModal: _vm.closeModal, report: _vm.report },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }