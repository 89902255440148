<template>
  <div>
    <Loader v-if="project == null" />
    <div v-else>
      <Prekickoff v-if="project.workspace_progress_level < 7" />
      <div v-if="project.workspace_progress_level >= 7">
        <!-- <ProjectManagerWorkSpace v-if="user.team_role == 'Product Manager'" />
        <DesignerWorkSpace v-if="user.team_role == 'Product Designer'" /> -->
        <EngineerWorkSpace />
        <!-- v-if="
            user.team_role == 'Fullstack Engineer' ||
            user.team_role == 'Backend Engineer' ||
            user.team_role == 'Frontend Engineer'
          " -->
        <!-- <QAWorkSpace
          v-if="user.team_role == 'QA Engineer' || user.team_role == 'QA/QC'"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Prekickoff from "./prekickoff";
import { getDevProject, getProjectActivityLog } from "@/api";
import Loader from "@/components/general/centerLoader";
import DesignerWorkSpace from "./Designer";
import EngineerWorkSpace from "./Engineers";
import ProjectManagerWorkSpace from "./ProjectManager";
import QAWorkSpace from "./QA";

export default {
  props: {},
  components: {
    Prekickoff,
    Loader,
    DesignerWorkSpace,
    ProjectManagerWorkSpace,
    EngineerWorkSpace,
    QAWorkSpace,
  },

  data: () => ({
    project: null,
    projectDoc: [],
    activitiesLog: [],
  }),

  created() {
    this.fetchProject();
    this.fetchActivitiesLog();
  },
  methods: {
    ...mapActions("devDashboard", [
      "updateSelectedProjectDoc",
      "setProjectActivity",
    ]),
    async fetchProject() {
      await getDevProject(this.$route.params.id).then((res) => {
        this.project = res.data.data.project;
        this.projectDoc = res.data.data;
        this.updateSelectedProjectDoc(res.data.data);
      });
    },
    fetchActivitiesLog() {
      getProjectActivityLog(this.$route.params.id)
        .then((res) => {
          if (res.data.status == 1) {
            const { data, current_page, last_page_url, next_page_url } =
              res.data;
            this.setProjectActivity(data.data);
          }
        })
        .catch((err) => {});
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    user() {
      return this.teamInfo.user;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
</style>