<template>
  <div>
    <Toolbar />
    <div class="pm-workspace">
      <div class="pm-container">
        <div class="progress-report-wrapper">
          <ProjectReport />
          <div class="project-activity">
            <ActivityLog v-bind="{ projectActivity, teamMembers }" />
          </div>
        </div>

        <center>
          <div class="row select-team-row" style="">
            <div class="col-9">
              <div class="bold-text-head">
                {{
                  IterationSprintStatus.iteration_id !== currentIteration.id
                    ? "Start Next Iteration "
                    : currentSprint
                    ? currentSprint.start_date == "" ||
                      currentSprint.start_date == null
                      ? "Sprint not started"
                      : "Change Task Status"
                    : "Sprint not started"
                }}
              </div>
              <div class="light-text-head">

                 {{
                  IterationSprintStatus.iteration_id !== currentIteration.id
                    ? "You are doing realy great. Kindly continue with your next iteration"
                    : currentSprint
                    ? currentSprint.start_date == "" ||
                      currentSprint.start_date == null
                      ? "You need to start your your next sprint. "
                      : "Update your task backlog status. This helps the product founder to be carried along"
                    : "You need to start your your next sprint."
                }}
               
              </div>
            </div>

            <div class="col-3">
              <div
                style="
                  float: none;
                  margin-top: 20px;
                  margin-bottom: 0px;

                  cursor: pointer;
                "
                @click="
                  IterationSprintStatus.iteration_id !== currentIteration.id
                    ? startNewIteration(IterationSprintStatus.iteration_id)
                    : currentSprint
                    ? currentSprint.start_date == '' ||
                      currentSprint.start_date == null 
                      ? (isOpenDialog = true)
                      : openTaskReportModal()
                    : (isOpenDialog = true)
                "
                class="btn grupa-blue-btn btn-lg proceedBtn"
              >
                {{
                  IterationSprintStatus.iteration_id !== currentIteration.id
                    ? "Start Iteration " + (this.currentIteration.it_number + 1)
                    : currentSprint
                    ? currentSprint.start_date == "" ||
                      currentSprint.start_date == null
                      ? "Start Sprint 1"
                      : " Update Backlog"
                    : "Start Sprint"
                }}
              </div>
            </div>
          </div>
        </center>

        <div
          class="modal"
          id="DeveloperTaskReport"
          data-target=".bd-example-modal-lg"
          tabindex="-1"
          aria-labelledby="DeveloperTaskReportLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">UPDATE TASKS ASSIGNED TO YOU</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body" style="text-align: center">
                <div
                  class="alert alert-warning"
                  style="margin-left: 80px; margin-right: 80px"
                >
                Updating your task by toggle between status based on your progress on  that task. You can also drag your each task to the appropriate status block
                </div>

                <DeveloperTaskReport />
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-light lightBorder"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="container-divider"></div>
        <!-- <SprintReport /> -->
        <!-- <ProjectDetails /> -->
      </div>
    </div>

    <StartSprint
      v-if="isOpenDialog"
      v-bind:form="sprintForm"
      v-bind="{ handleStartSprint, start_spinner }"
      v-bind:closeDialog="closeStartModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Toolbar from "@/components/toolbar/devProjectToolbar";
import ProjectReport from "@/components/workspace/projectReport";
import ActivityLog from "@/components/workspace/activityLog";
import DeveloperTaskReport from "./taskReport";
import { devStartSprintOne } from "@/api";
import ProjectDetails from "@/components/workspace/projectDetails";
import StartSprint from "@/views/DevDashboard/products/roadmap/startSprint.vue";
import {
  formatDayMonth,
  businessDaysDiffInDays,
  addDaysFromDate,
} from "@/utils/dateHelpers";
// import SprintReport from "@/components/workspace/sprintReport";
export default {
  props: {},
  components: {
    Toolbar,
    ProjectReport,
    ActivityLog,
    DeveloperTaskReport,
    ProjectDetails,
    StartSprint,
  },

  data: () => ({
    isOpenDialog: false,
    sprintForm: {
      startMenu: false,
      start_date: "",
      end_date: "",
    },
    start_spinner: false,
  }),

  watch: {
    "sprintForm.start_date": {
      handler: function (newValue) {
        let new_date = this.$moment(newValue, "YYYY-MM-DD").add(5, "days");
        this.sprintForm.end_date = new_date.format("YYYY-MM-DD");
      },
    },
  },
  mounted() {
    console.log(this.tasks);
    if (this.$route.name == "dev_backlog") {
      this.openTaskReportModal();
    }
    //console.log(this.currentSprint);
    //console.log(this.projectObj);
  },
  created() {},
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", ["updateSelectedProject"]),


    startNewIteration(id) {
      this.isOpenDialog = true
      //alert("Under construction :: " + id);
    },
    openTaskReportModal() {
      $("#DeveloperTaskReport").modal("show");
    },

    closeStartModal() {
      this.isOpenDialog = false;
    },

    handleStartSprint() {
      //
      this.start_spinner = true;
      const d = new Date();
      const hr = d.getHours();
      const mi = d.getMinutes();
      const se = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();

      let timmer = ` ${hr}:${mi}:${se} `;
      let payload = {
        start_date: this.sprintForm.start_date, //+ timmer,
        progress_level: 8,
        project_id: this.$route.params.id,
        iteration_id: this.currentIteration.id,
      };
      // alert(JSON.stringify(payload))

      devStartSprintOne(payload)
        .then((res) => {
          this.start_spinner = false;
          if (res.data.status == 1) {
            this.isOpenDialog = false;
            this.product = res.data.data.project;
            this.updateSelectedProject(res.data.data.project);
            this.setSuccessMsg("Sprint 1 Started!!");
           // alert("Sprint 1 started");
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err)
          this.start_spinner = false;
        });
    },
  },
  computed: {
    ...mapState("devDashboard", [
      "projectActivity",
      "teamInfo",
      "selectedProjectDoc",
      "selectedProject",
    ]),
    ...mapState("clientDashboard", ["clientProject", "clientProjectDoc"]),

    IterationSprintStatus() {
      //console.log(this.selectedProjectDoc);
      return this.selectedProjectDoc.sprints.find(
        (sprint, i) => sprint.status == 0
      );
    },
    sprintDate() {
      let { start_date } = this.currentSprint;
      if (start_date !== null) {
        return `${this.$moment(start_date).format("Do MMM")} -
          ${this.addDaysFromDate(start_date, 4)}`;
      } else {
        return "Not Started";
      }
    },
    teamMembers() {
      return this.teamInfo.team.members;
    },
    projectObj() {
      return this.clientProject;
    },
    projectDoc() {
      return this.clientProjectDoc.documents;
    },

    projectDocMain() {
      return this.clientProjectDoc;
    },
    currentIteration() {
      return this.projectDoc.iterations.find(
        (itr) => itr.id == this.clientProject.current_iteration
      );
    },

    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
    currentSprint() {
      return this.selectedProjectDoc.sprints.find(
        (sprint) => sprint.status == 1
      );
    },
    tasks() {
      return this.selectedProjectDoc.tasks.filter(
        (task) => task.sprint_id == this.currentSprint.id
      );
    },
    completedTasks() {
      return this.tasks.filter((task) => task.task_status_id == 5);
    },

    currentFeatures() {
      return this.selectedProjectDoc.features.filter(
        (feature) => feature.iteration_id == this.currentIteration.id
      );
    },
    iterationStartDate() {
      let { start_date, due_date } = this.currentIteration;
      if (start_date !== null) {
        return `${this.$moment(start_date).format("Do MMM")} -
        ${this.$moment(due_date).format("Do MMM")} `;
      } else {
        return "Not Started";
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.select-team-row {
  background-color: black;
  width: auto;
  margin: 40px;
  padding: 20px;
  margin-bottom: -10px;
}

.light-text-head {
  margin-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;

  text-align: left;
}
.bold-text-head {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #ffffff;
  /*  text-transform: uppercase; */
  text-align: left;
}

.modal-dialog {
  width: max-content !important;
  max-width: none !important;
}

.modal-body {
  background: #f7f7f8;
}
</style>
