<template>
  <div class="project-details-conatiner">
    <div class="contain-title">PRODUCT DETAILS</div>
    <div class="project-info-wrapper">
      <div class="project-main-info">
        <div class="product-name">{{ project.project_name }}</div>
        <div class="product-description">
          {{ project.overview }}
        </div>
        <div class="product-industry">
          <div>
            <img
              :src="`/img/onboarding/platforms/${replaceUnderscore(
                project.project_type
              )}.svg`"
              alt=""
            />
          </div>
          <div>
            <img
              :src="`/image/clientProjectCategories/${project.category.toLowerCase()}.svg`"
              alt=""
            />
          </div>
          <div><img :src="`${imgLink}platform3.svg`" /></div>
        </div>
        <div v-if="project.owner" class="project-owner">
          <div class="name-short">
            {{ abbrevateName(project.owner.name).toUpperCase() }}
          </div>
          <div style="margin-left: 8px">
            <div class="info-name">{{ project.owner.name }}</div>
            <div class="info-rate">Unrated</div>
          </div>
        </div>
      </div>
      <div class="middle-container">
        <div class="left-div">
          <div class="grey-header">PReferred tech</div>
          <div class="no-option">No Preference</div>
          <div class="similar-site">similar sites</div>
          <div
            v-if="project.similar_projects"
            class="all-site"
            style="width: 189px"
          >
            <div
              class="div-site"
              v-for="(site, i) in parseToJson(project.similar_projects)"
              :key="i"
            >
              {{ site }}
            </div>
          </div>
        </div>
        <div class="right-div">
          <div class="grey-header">service plan</div>
          <div class="plan-info">
            <div class="plan-icon" style="margin-top: 4px">
              <img src="/img/onboarding/upstart-icon.svg" />
            </div>
            <div style="margin-left: 4px">
              <div class="info-name">{{ project.experience }}</div>
              <div class="info-rate">
                ${{
                  (project.price / project.iteration_cycle).toLocaleString()
                }}/weekly sprint
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="team-developers">
        <div
          class="members-box"
          v-for="(member, i) in teamMembers"
          :key="i"
          v-if="member.name"
        >
          <div class="profile-image">
            <v-avatar
              :size="24"
              color="grey lighten-4"
              class="profile_bg"
              :style="{
                backgroundImage: `url(${
                  $imageUrl + member.profile_picture || test
                })`,
              }"
            ></v-avatar>
          </div>
          <div class="dev-details">
            <div class="dev-name">{{ shortenName(member.name) }}</div>
            <div class="dev-role">
              {{ member.team_lead == 1 ? "Team Lead/" : "" }}
              {{ member.team_role.split(" ")[0] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import shortName from "@/mixins/shortName";
import appMixin from "@/mixins/appMixin";
export default {
  mixins: [appMixin, shortName],
  props: {},
  components: {},

  data: () => ({
    imgLink: "/img/dashboard/client-icons/",
    similarSites: [
      "Grupa.io",
      "masterclass.com",
      "tiktok",
      "DSTV",
      "LatticeHq",
    ],
  }),

  created() {},
  methods: {},
  computed: {
    ...mapState("devDashboard", ["teamInfo", "selectedProject"]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
    project() {
      return this.selectedProject;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.project-details-conatiner {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 24px 16px 24px;
  margin-top: 16px;
}
.contain-title {
  text-align: left;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.project-info-wrapper {
  display: flex;
  margin-top: 24px;
}
.product-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.project-main-info {
  text-align: left;
  border-right: 1px solid #e4e5e7;
  padding: 0px 24px 16px 0px;
}
.product-description {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 8px;
  width: 341px;
}
.product-industry {
  margin-top: 24px;
  display: flex;
}
.product-industry div {
  margin-right: 24px;
}
.project-owner {
  display: flex;
  margin-top: 16px;
}
.name-short {
  background: #0781f2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 130%;
  color: #ffffff;
  margin-top: 4px;
}
.info-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.info-rate {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 2px;
}
.middle-container {
  text-align: left;
  border-right: 1px solid #e4e5e7;
  padding: 0px 24px 16px 24px;
  display: flex;
}
.right-div {
  margin-left: 87px;
}
.similar-site,
.grey-header {
  font-size: 12px;
  line-height: 130%;
  text-transform: uppercase;
  color: #979da5;
}
.similar-site {
  margin-top: 48px;
}
.no-option {
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  margin-top: 12px;
}
.all-site,
.plan-info {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
}
.div-site {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin: 0px 8px 8px 0px;
}
.info-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.info-rate {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 2px;
}
.team-developers {
  display: flex;
  padding: 0px 24px 16px 24px;
  text-align: left;
  flex-wrap: wrap;
}
.members-box {
  display: flex;
  margin-bottom: 12px;
  width: 116px;
}
.dev-details {
  margin-left: 8px;
}
.dev-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.dev-role {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 2px;
}
</style>
