var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [_vm._v("Report Issues")]),
        _c("div", { staticClass: "close-btn", on: { click: _vm.closeModal } }, [
          _c("img", {
            attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
          }),
        ]),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "link-url-div" }, [
          _vm._v(
            "\n        You’re behind expected the schedule. Tell us what the blocker is and\n        how we can help you\n      "
          ),
        ]),
        _c("div", { staticClass: "form-grouped" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.report.issues,
                expression: "report.issues",
              },
            ],
            staticClass: "form-control",
            staticStyle: { height: "unset" },
            attrs: {
              name: "overview",
              maxlength: "160",
              rows: "4",
              id: "textarea",
              placeholder: "What’s the blocker?",
            },
            domProps: { value: _vm.report.issues },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.report, "issues", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dialog-footer" }, [
      _c(
        "button",
        {
          staticClass: "grupa-grey-btn",
          staticStyle: { "margin-right": "24px", color: "#53585f" },
        },
        [_vm._v("\n        Send to Grupa\n      ")]
      ),
      _c("button", { staticClass: "grupa-blue-btn" }, [_vm._v("Send to PM")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }