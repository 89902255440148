var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-layout" }, [
        _vm._m(0),
        _c("div", { staticClass: "platform-agreement" }, [
          _c("div", { staticClass: "document-name" }, [
            _vm._m(1),
            _c("div", { staticClass: "product-name" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.projectDoc.project.project_name) +
                  " " +
                  _vm._s("<>") +
                  "\n          "
              ),
              _c("span", [
                _vm._v(" Product Team Service Level Agreement.pdf "),
              ]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "open-action",
              on: { click: _vm.handleOpenAgreement },
            },
            [_vm._v("Open")]
          ),
        ]),
      ]),
      _c(
        "GrupaSLA",
        _vm._b(
          {
            ref: "grupa_sla",
            staticStyle: { overflow: "hidden", height: "0" },
            attrs: { id: "grupa-sla", devUser: true },
          },
          "GrupaSLA",
          { teamDetails: _vm.teamDetails, projectDoc: _vm.projectDoc },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-cover" }, [
      _c("div", { staticClass: "sla-page" }, [
        _vm._v(
          "\n        This document captures the details of service expected. Please go\n        through it and confirm that everything is in order.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "document-icon" }, [
      _c("img", { attrs: { src: "/img/doc-icons/pdf.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }