<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">
          Mark Task as Complete - <span style="color: #979da5">For Review</span>
        </div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="link-url-div">
          Add link to completed task e.g. link to design files, host url, link
          to document etc.
        </div>
        <div class="completed-task">
          {{ currentTask.task }}
        </div>
        <!-- <input
          class="form-control"
          name="organization"
          v-model="form.url"
          placeholder="https://"
          id="url-link"
          type="text"
        />
        <div class="tips-div">
          This would be added as a comment in the task’s view
        </div> -->
      </div>
      <div class="dialog-footer">
        <button
          class="grupa-grey-btn"
          style="margin-right: 24px; color: #53585f"
          @click="closeDialog"
        >
          No, Go back
        </button>
        <button @click="markTaskAsCompleted" class="grupa-success-btn">
          Mark as Complete
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="spinner"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    form: Object,
    markTaskAsCompleted: {
      type: Function,
      required: true,
    },
    currentTask: Object,
    spinner: Boolean,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
  text-align: left;
  width: 356px;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}
.link-url-div {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.completed-task {
  background: #f7f7f8;
  border-radius: 4px;
  font-size: 12px;
  line-height: 130%;
  color: #53585f;
  padding: 16px;
  margin-top: 16px;
}
.form-control {
  margin-top: 16px;
  margin-bottom: 0px;
}
.tips-div {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 8px;
}
</style>


