<template>
  <div>
    <div class="page-layout">
      <div class="section-cover">
        <div class="alert alert-warning" style="width:540px">You do not need to do anything at this stage</div>
        <div class="schedule-meeting">KickOff</div>
        <div
          v-if="project.preview_meeting_start_date == null"
          class="meeting-info"
          style="width: 521px"
        >
          <!-- Start work immediately with the planning and roadmapping phases of the
          building the product. -->

          Wait for the product owner to complete the next steps and kickoff
        </div>
        <div v-else class="meeting-info">
          Meeting has been scheduled and invites sent ot all team members added
          to to this project.
        </div>
        <div
          v-if="project.preview_meeting_start_date == null"
          class="calendar-embed"
        >
          <div class="meeting-details">
            <img src="/img/onboarding/schedule-icon.svg" />
            <div class="meeting-time">
              Waiting for Product owner to proceed...
            </div>
          </div>
        </div>
        <div v-else class="calendar-embed">
          <div class="meeting-details">
            <img src="/img/onboarding/schedule-icon.svg" />
            <div class="meeting-time">
              Meeting scheduled for
              <span style="">
                {{
                  $moment(project.preview_meeting_start_date).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )
                }}
              </span>
            </div>
            <div class="meeting-link">
              Meeting link:
              <span
                ><a
                  target="_blank"
                  :href="project.preview_meeting_hangout_link"
                >
                  {{ project.preview_meeting_hangout_link }}
                </a></span
              >
            </div>
          </div>
        </div>
        <div class="bottom-div">
          <div class="meeting-goal">
            <div class="meeting-header">THESE WILL HAPPEN NEXT</div>
            <ol type="1">
              <li v-for="(obj, i) in objectives" :key="i" class="objectives">
                <!--  <img src="/img/onboarding/grey-checked.svg" /> -->
                {{ obj }}
              </li>
            </ol>
          </div>
          <div
            class="meeting-attendees"
            v-if="project.preview_meeting_start_date !== null"
          >
            <div class="meeting-header">
              MEETING INVITEES ({{ teamMembers.length + 1 }})
            </div>
            <div
              class="members-box"
              v-for="(member, i) in teamMembers"
              :key="i"
            >
              <div class="team-member">
                <div class="profile-image">
                  <v-avatar
                    :size="32"
                    color="grey lighten-4"
                    class="profile_bg"
                    :style="{
                      backgroundImage: `url(${
                        $imageUrl + member.profile_picture || test
                      })`,
                    }"
                  ></v-avatar>
                </div>
                <div class="dev-details">
                  <div class="dev-name">{{ member.name }}</div>
                  <div class="dev-role">
                    {{ member.team_lead == 1 ? "Team Lead/" : "" }}
                    {{ member.team_role }}
                  </div>
                </div>
              </div>
            </div>
            <div class="members-box">
              <div class="team-member">
                <div class="profile-image">
                  <div
                    class="grupa-icon user-name-icon"
                    style="height: 32px; width: 32px"
                  >
                    GR
                  </div>
                </div>
                <div class="dev-details">
                  <div class="dev-name">Grupa</div>
                </div>
              </div>
            </div>
            <div class="call-teammembers">
              Reach out to your teammates to accept the invite
            </div>
          </div>
        </div>
      </div>
    </div>
    <RateMeetingDialog
      meetingTitle="Client"
      v-if="openDialog"
      v-bind="{
        closeDialog,
        submitRating,
        ratingSpinner,
        ratingSucess,
        failForm,
        isMeetingHold,
        handleMeetingStatus,
        isRatingForm,
      }"
      v-bind:form="ratingForm"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { rateFirstMeeting } from "@/api";
import RateMeetingDialog from "@/components/Modals/rateMeeting";
export default {
  props: {},
  components: {
    RateMeetingDialog,
  },

  data: () => ({
    openDialog: false,
    objectives: [
      "The Product Owner will sign a Platform Agreement with Grupa. The agreement confirms the number of experts he gets and agreed compensation method",
      "The Product Owner will pay the kick-off fee. This covers payment for the 1 week of kickoff meetings and road mapping",
      // "Product Owner decides to continue with your team or not.",
    ],
    profile: "/img/onboarding/company/profile.jpeg",
    test: "/img/profile.svg",
    ratingForm: {
      value: "",
      comment: "",
    },
    ratingSpinner: false,
    failForm: {
      reason: "",
    },
    isMeetingHold: false,
    ratingSucess: false,
    isRatingForm: true,
    grupaLogo: "/img/profile/grupa-logo.png",
  }),

  created() {},
  mounted() {
    this.showRatingDialog();
  },
  methods: {
    closeDialog() {
      this.openDialog = false;
    },
    submitRating() {
      let { comment, value } = this.ratingForm;
      if (value !== "") {
        this.ratingSpinner = true;
        let payload = {
          comment,
          meeting_rating_id: value,
          project_id: this.$route.params.id,
          user_type_id: 1,
        };
        rateFirstMeeting(payload)
          .then((resp) => {
            this.ratingSpinner = false;
            this.ratingSucess = true;
            this.isRatingForm = false;
          })
          .catch((error) => {
            this.ratingSpinner = false;
          });
      }
    },
    showRatingDialog() {
      if (
        this.project.is_dev_has_rate_meeting == 0 &&
        this.isMeetingDone() == true
      ) {
        this.openDialog = true;
      }
    },
    isMeetingDone() {
      return this.$moment(this.project.preview_meeting_start_date).isBefore(
        this.$moment()
      );
    },
    handleMeetingStatus() {
      this.isMeetingHold = true;
      this.isRatingForm = false;
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProject", "teamInfo"]),
    project() {
      return this.selectedProject;
    },
    progressLevel() {
      return this.selectedProject.workspace_progress_level;
    },
    teamMembers() {
      return this.teamInfo.team.members;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.schedule-meeting {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.schedule-meeting span {
  color: #0781f2;
}
.section-cover {
  text-align: left;
  /* width: 521px; */
}
.meeting-info {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 8px;
}
.calendar-embed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 263px;
  width: 521px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
}
.schedule-text {
  font-weight: 900;
  font-size: 32px;
  line-height: 38px;
  color: #c8cbd0;
  width: 360px;
  text-align: center;
  cursor: pointer;
}
.meeting-goal {
}
.objectives {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 12px;
  max-width: 500px;
}
.meeting-details {
  text-align: center;
}
.meeting-time {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
}
.meeting-time span {
  color: #1b1e22;
}
.meeting-link {
  font-size: 12px;
  line-height: 140%;
  color: #c8cbd0;
  margin-top: 16px;
}
.meeting-link span {
  color: #0781f2;
  cursor: pointer;
}
.meeting-link span a {
  text-decoration: none;
}
.bottom-div {
  display: flex;
  margin-top: 24px;
}
.meeting-attendees {
  margin-left: 150px;
  text-align: left;
}
.meeting-header {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.members-box {
  display: flex;
  margin-top: 16px;
}
.team-member {
  display: flex;
  width: 220px;
}
.dev-details {
  margin-left: 8px;
}
.dev-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.dev-role {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 2px;
}
.profile_bg {
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-position: top;
}
.check-icon {
  margin-left: 100px;
}
.call-teammembers {
  font-size: 12px;
  line-height: 140%;
  color: #1fa564;
  margin-top: 16px;
}
.selected-rating-icon img {
  width: 104px;
  height: 104px;
}
</style>
