var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "page-layout" }, [
        _c("div", { staticClass: "container-title" }, [
          _vm._v("Generate Design Sprint schedule"),
        ]),
        _c("div", { staticClass: "description" }, [
          _vm._v(
            "\n      Select the date you have agreed to start design sprint and click to\n      generate schedule.\n    "
          ),
        ]),
        _c("div", { staticClass: "empty-box" }, [
          _c("div", [
            _c("img", { attrs: { src: "/img/onboarding/schedule-icon.svg" } }),
          ]),
          _c("div", { staticClass: "waiting-msg" }, [
            _vm._v(
              "\n        Waiting for Product owner to pay for Design Sprint...\n      "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }