var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "nav-wrapper" }, [
      _c("div", { staticStyle: { display: "flex" } }, [
        _c("div", { staticClass: "project-space" }, [
          _c("div", { staticClass: "project-name" }, [
            _c("img", {
              staticStyle: { "margin-right": "8px", cursor: "pointer" },
              attrs: { src: "/img/dashboard/go-back.svg" },
              on: { click: _vm.backToProjects },
            }),
            _vm._v(
              "\n          " + _vm._s(_vm.project.project_name) + "\n        "
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "nav-items-container" },
          _vm._l(_vm.tabs, function (tab, index) {
            return _c(
              "div",
              {
                key: tab.id,
                on: {
                  "!click": function ($event) {
                    return _vm.handleRouteNavigation($event, tab)
                  },
                },
              },
              [
                _c(
                  "router-link",
                  {
                    key: index,
                    staticClass: "sb-tab-menu c-pointer",
                    attrs: {
                      exact: "",
                      to: { name: tab.route_name },
                      tag: "div",
                      "active-class": "selected-tab",
                    },
                  },
                  [
                    _c("div", { staticClass: "nav-item" }, [
                      _vm._v(
                        "\n              " + _vm._s(tab.name) + "\n            "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "slot-content" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }