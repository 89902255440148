<template>
  <div class="move-task-div">
    <div class="move-task-header">
      Move Task
      <img
        src="/img/lightbox/preloader.gif"
        style="float: right"
        class="button-spinner"
        v-show="spinner"
      />
    </div>
    <div
      class="task-single-stage"
      v-for="(stage, j) in taskProgress"
      :key="j"
      v-if="stage.task_status !== currentStage"
      @mouseover="
        selected = true;
        currentHover = stage.task_status;
      "
      @mouseleave="
        selected = false;
        currentHover = '';
      "
      @click="handleMoveTask(stage.task_status)"
    >
      <div style="display: flex">
        <div class="medium-icon"><img :src="imgLink + stage.img" /></div>
        <div class="stage-text-span">{{ stage.name }}</div>
      </div>
      <div v-if="selected && currentHover == stage.task_status">
        <img src="/img/dashboard/task/checkmark.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    currentTask: Object,
    handleMoveTask: Function,
    currentStage: Number,
    taskProgress: {
      type: Array,
      required: true,
    },
    spinner: {
      required: true,
      type: Boolean,
    },
  },
  components: {},

  data: () => ({
    profile: "/img/onboarding/company/profile.jpeg",
    currentHover: "",
    selected: false,
    imgLink: "/img/dashboard/task/",
  }),

  created() {},
  methods: {},
  computed: {
    ...mapState("devDashboard", ["selectedProjectDoc", "teamInfo"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.move-task-div {
  /* position: absolute; */
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 5px 15px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  /* width: 274px; */
  width: 310px;
  text-align: left;
}
.move-task-header {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 24px 24px 12px;
}
.task-single-stage {
  padding: 9px 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
}
.task-single-stage:hover {
  background: #f7f7f8;
}
.stage-text-span {
  margin-top: 3px;
  margin-left: 8px;
}
.medium-icon img {
  height: 24px;
  width: 24px;
}
</style>
