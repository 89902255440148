import { render, staticRenderFns } from "./designerTasksReport.vue?vue&type=template&id=b1f0de3c&scoped=true&"
import script from "./designerTasksReport.vue?vue&type=script&lang=js&"
export * from "./designerTasksReport.vue?vue&type=script&lang=js&"
import style0 from "./designerTasksReport.vue?vue&type=style&index=0&id=b1f0de3c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1f0de3c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oeze/Documents/grupa/grupa-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b1f0de3c')) {
      api.createRecord('b1f0de3c', component.options)
    } else {
      api.reload('b1f0de3c', component.options)
    }
    module.hot.accept("./designerTasksReport.vue?vue&type=template&id=b1f0de3c&scoped=true&", function () {
      api.rerender('b1f0de3c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DevDashboard/products/workspace/Designer/designerTasksReport.vue"
export default component.exports