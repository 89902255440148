<template>
  <div>
    <div class="nav-wrapper">
      <div style="display: flex">
        <div class="project-space">
          <div class="project-name">
            <img
              @click="backToProjects"
              src="/img/dashboard/go-back.svg"
              style="margin-right: 8px; cursor: pointer"
            />
            {{ project.project_name }}
          </div>
        </div>
        <div class="nav-items-container">
          <div
            v-for="(tab, index) in tabs"
            :key="tab.id"
            @click.capture="handleRouteNavigation($event, tab)"
          >
            <router-link
              exact
              :key="index"
              :to="{ name: tab.route_name }"
              tag="div"
              class="sb-tab-menu c-pointer"
              active-class="selected-tab"
            >
              <div class="nav-item">
                {{ tab.name }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="slot-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {},
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: "Workspace",
          active: true,
          route_name: "dev_workspace",
        },
        {
          id: 2,
          name: "Roadmap",
          active: false,
          route_name: "roadmap",
        },
        {
          id: 3,
          name: "Analytics",
          active: false,
          route_name: "team_analytics",
        },
        {
          id: 4,
          name: "Documents",
          active: false,
          route_name: "team_doc",
        },
        {
          id: 5,
          name: "Team Mgt",
          active: false,
          route_name: "team_mgt",
        },
        // {
        //   id: 5,
        //   name: "Schedule Meetings",
        //   active: false,
        //   route_name: "schedule_meetings",
        // },
      ],
    };
  },
  mounted() {},
  methods: {
    backToProjects() {
      this.$router.push({ name: "dev_projects" });
    },
    handleRouteNavigation(e, tab) {
      if (
        this.project.workspace_progress_level < 7 &&
        tab.route_name == "team_analytics"
      ) {
        e.preventDefault();
      }
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProject"]),
    project() {
      return this.selectedProject;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.nav-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  height: 64px;
  padding: 0px 32px;
  display: flex;
  position: fixed;
  width: calc(100% - 200px);
  justify-content: space-between;
  top: 0px;
  right: 0px;
  /* z-index: 700; */
}
.project-space {
  padding: 16px 0px;
}
.project-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  border-right: 1px solid #e4e5e7;
  padding: 6px 24px 7px 0px;
}
.nav-items-container {
  display: flex;
  margin-left: 24px;
}
.nav-item {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-right: 40px;
  padding: 22px 0px 23px 0px;
}
.selected-tab .nav-item {
  color: #1b1e22;
  border-bottom: 1px solid #1b1e22;
}
.c-pointer {
  cursor: pointer;
}
</style>