var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vetting-progress" }, [
      _c(
        "div",
        { staticClass: "progress-wrapper" },
        _vm._l(_vm.vettingStages, function (stage, k) {
          return _c("div", { key: k }, [
            k < 2
              ? _c("div", [
                  _c("div", { staticClass: "progress-stage" }, [
                    _c("img", {
                      attrs: { src: "/img/onboarding/tick-icon.svg", alt: "" },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "progress-name",
                        staticStyle: { color: "#1b1e22" },
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s(stage) + "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", {
                    staticClass: "progress-line",
                    staticStyle: { "border-left": "solid 3px #1b1e22" },
                  }),
                ])
              : _vm._e(),
            k == 2 || k == 3
              ? _c("div", [
                  _c("div", { staticClass: "progress-stage" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "/img/onboarding/" +
                          (_vm.progressLevel <= k
                            ? "green-dot.svg"
                            : "tick-icon.svg"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "progress-name",
                        style:
                          _vm.progressLevel == k ||
                          _vm.progressLevel == 3 ||
                          (_vm.progressLevel == 5 && k == 3) ||
                          (_vm.progressLevel == 4 && k == 3)
                            ? "color: #1fa564;"
                            : "color: #1b1e22;",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              k == 2 ? "KickOff" : k == 3 ? "DrawRoad Map" : ""
                            ) +
                            "\n\n              "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", {
                    staticClass: "progress-line",
                    style:
                      _vm.progressLevel < k
                        ? "border-left: solid 3px #54CF94;"
                        : "border-left: solid 3px #1b1e22",
                  }),
                ])
              : _vm._e(),
            k > 3
              ? _c("div", [
                  _c("div", { staticClass: "progress-stage" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "/img/onboarding/" +
                          (_vm.progressLevel == k
                            ? "green-dot.svg"
                            : _vm.progressLevel < k
                            ? "black-dot.svg"
                            : "tick-icon.svg"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "progress-name",
                        style:
                          _vm.progressLevel > k && _vm.progressLevel != 5
                            ? "color: #1fa564;"
                            : _vm.progressLevel > 2
                            ? "color: #1b1e22;"
                            : "",
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s(stage) + "\n            "
                        ),
                      ]
                    ),
                  ]),
                  k < 6
                    ? _c("div", {
                        staticClass: "progress-line",
                        style:
                          _vm.progressLevel == k
                            ? "border-left: solid 3px #54CF94;"
                            : _vm.progressLevel > k
                            ? "border-left: solid 3px #1b1e22;"
                            : "",
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }