<template>
  <div>
    <div class="page-layout">
      <div class="section-cover">
        <div class="sla-page">
          This document captures the details of service expected. Please go
          through it and confirm that everything is in order.
        </div>
      </div>
      <!-- Content -->
      <div class="platform-agreement">
        <div class="document-name">
          <div class="document-icon">
            <img src="/img/doc-icons/pdf.svg" alt="" />
          </div>
          <div class="product-name">
            {{ projectDoc.project.project_name }} {{ `<>` }}
            <span> Product Team Service Level Agreement.pdf </span>
          </div>
        </div>
        <div @click="handleOpenAgreement" class="open-action">Open</div>
      </div>
    </div>
    <GrupaSLA
      ref="grupa_sla"
      id="grupa-sla"
      style="overflow: hidden; height: 0"
      v-bind="{ teamDetails, projectDoc }"
      v-bind:devUser="true"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import html2canvas from "html2canvas";
window.html2canvas = html2canvas;
import html2pdf from "html2pdf.js";
import GrupaSLA from "@/components/general/grupaSLA";

export default {
  props: {},
  components: {
    GrupaSLA,
  },

  data: () => ({}),

  created() {},
  methods: {
    handleOpenAgreement() {
      const elementHTML = this.$refs.grupa_sla.$refs.slaWrapper;
      const settings = this.pdfSettings("platformAgreement");
      const doc = html2pdf().set(settings).from(elementHTML);
      doc.output("datauristring").then((pdfAsString) => {
        let output = this.base64ToBlob(pdfAsString.split("base64,")[1]);
        var fileURL = URL.createObjectURL(output);
        window.open(fileURL);
      });
    },
    base64ToBlob: function (base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "application/pdf" });
    },
    pdfSettings(fileName) {
      let today = new Date();
      const date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`;
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const dateTime = `${date}_${time}`;
      return {
        margin: [15, 15],
        filename: `SLA_${fileName}_${dateTime}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
        pageBreak: { mode: ["avoid-all", "css", "legacy"] },
      };
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProjectDoc", "teamInfo"]),
    teamDetails() {
      return this.teamInfo.team;
    },
    projectDoc() {
      return this.selectedProjectDoc;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-cover {
  text-align: left;
  width: 521px;
}
.sla-page {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.sla-file {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 24px;
}
.sla-title {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1b1e22;
  margin-bottom: 16px;
}
.sla-content {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-bottom: 24px;
}
.doc-scroll-wrapper {
  height: 473px;
  overflow-y: scroll;
  margin-top: 16px;
}
.platform-agreement {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
  width: 600px;
}
.document-name {
  display: flex;
  align-items: center;

  gap: 24px;
}
.document-icon img {
  height: 32px;
  width: 32px;
}
.product-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
.product-name span {
  color: #979da5;
}
.open-action {
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #0781f2;
}
</style>
