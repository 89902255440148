<template>
  <div>
    <div class="route-navbar">
      <Toolbar>
        <div class="btn-wrap">
          <button
            v-if="
              project.workspace_progress_level == 3 && sprintArray.length == 0
            "
            class="grupa-blue-btn"
            @click="scheduleDesignSprintDays"
          >
            Generate Schedule
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
          <button
            class="grupa-blue-btn"
            v-if="
              project.workspace_progress_level == 3 &&
              sprintArray.length > 0 &&
              !isDesignOver
            "
            @click="editSprint = true"
          >
            Edit Schedule
          </button>
          <button
            v-if="
              project.workspace_progress_level >= 3 && !project.share_roadmap
            "
            class="grupa-blue-btn"
            @click="drawRoadMap"
          >
            Draw Roadmap
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
          <button
            v-if="project.share_roadmap && !project.accept_roadmap"
            class="grupa-disabled-btn"
          >
            Awaiting Approval
          </button>
          <button
            v-if="
              project.workspace_progress_level >= 5 &&
              !project.team_accept_sla &&
              project.accept_roadmap
            "
            class="grupa-blue-btn"
            @click="handleAcceptSLA"
          >
            Agree & Execute
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
        </div>
      </Toolbar>
    </div>
    <div class="prekickoff-wrapper">
      <ProgessBar />
      <div class="right-container">
        <MeetTheClient
          v-if="
            project.workspace_progress_level == 2 || project.workspace_progress_level == 3 &&
            project.is_dev_has_rate_meeting == 0
          "
        />
        <WaitForSprintPayment
          v-if="
            project.workspace_progress_level <= 2 &&
            project.is_dev_has_rate_meeting == 1
          "
        />

       <!--  <ScheduleDesignSprint
          v-if="
            project.workspace_progress_level == 3 && sprintArray.length == 0
          "
          ref="sprintSchedule"
          v-bind="{ scheduleDesignSprintDays }"
        /> -->
        <DesignSprint
          v-if="
            project.workspace_progress_level == 3 &&
            sprintArray.length > 0 &&
            !isDesignOver
          "
          v-bind="{ sprintArray, updateSprintArray }"
        />
        <EditDesignSprintSchedule
          v-if="editSprint"
          v-bind="{ closeDialog, updateDesignSprintSchedule, editSpinner }"
        />
        <DrawRoadMap
          v-if="
            project.workspace_progress_level == 5 && !project.accept_roadmap
          "
        />
        <!-- <DrawRoadMap v-if="project.workspace_progress_level == 5" /> -->
        <SLADOC
          v-if="
            project.workspace_progress_level >= 5 &&
            !project.team_accept_sla &&
            project.accept_roadmap
          "
        />
        <StartProductDevelopment
          v-if="
            project.workspace_progress_level == 6 &&
            project.team_accept_sla &&
            project.accept_roadmap
          "
        />
        <AlertSuccess v-if="successMsg" time="7" />
        <AlertError v-if="errorMsg" time="7" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  createDesignSprint,
  teamAcceptMeetingInvite,
  fetchProjectDesignSprint,
  startRoadMap,
} from "@/api";
import { updateDesignSprint, teamAcceptSLA } from "@/api/team";
import ProgessBar from "./progressBar";
import MeetTheClient from "./meetDClient";
import DesignSprint from "./designSprint";
import WaitForSprintPayment from "./waitForSprintPayment";
import ScheduleDesignSprint from "./scheduleDesignSprint";
import EditDesignSprintSchedule from "./editSprintSchedule";
import DrawRoadMap from "./drawRoadMap";
import Toolbar from "@/components/toolbar/devProjectToolbar";
import SLADOC from "./SLADoc";
import StartProductDevelopment from "./startProductDevelopment";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";

export default {
  props: {},
  components: {
    ProgessBar,
    MeetTheClient,
    DesignSprint,
    ScheduleDesignSprint,
    EditDesignSprintSchedule,
    DrawRoadMap,
    Toolbar,
    SLADOC,
    StartProductDevelopment,
    WaitForSprintPayment,
    AlertSuccess,
    AlertError,
  },

  data: () => ({
    spinner: false,
    sprintArray: [],
    isDesignOver: null,
    editSprint: false,
    editSpinner: false,
  }),

  created() {
    this.fetchDesignSprint();
  },
  mounted() {},
  watch: {
    sprintArray: function (newVal) {
      if (newVal.length > 0) {
        let currentDFate = this.sprintArray[4].date;
        this.isDesignOver = this.$moment(currentDFate).isBefore(this.$moment());
      }
    },
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", ["updateSelectedProject"]),
    ...mapActions("devPrekickoff", ["setDesignSprintDays"]),

    async scheduleDesignSprintDays() {
      let formData = this.$refs.sprintSchedule.handleDesignSprint();
      if (formData !== null) {
        this.spinner = true;
        await createDesignSprint(formData)
          .then((resp) => {
            this.spinner = false;
            this.sprintArray = resp.data.data;
            this.setDesignSprintDays(resp.data.data);
          })
          .catch((error) => {
            this.spinner = false;
          });
      }
    },
    async updateDesignSprintSchedule(form) {
      this.editSpinner = true;
      let formData = form;
      updateDesignSprint(formData)
        .then((resp) => {
          this.sprintArray = resp.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.editSpinner = false;
          this.editSprint = false;
        });
    },
    async handleAcceptMeeting() {
      this.spinner = true;
      let project_id = this.$route.params.id;
      await teamAcceptMeetingInvite(project_id)
        .then((resp) => {
          this.spinner = false;
          this.updateSelectedProject(res.data.data);
          // return resp.data;
        })
        .catch((error) => {
          this.spinner = false;
        });
    },
    fetchDesignSprint() {
      let project_id = this.$route.params.id;
      fetchProjectDesignSprint(project_id)
        .then((resp) => {
          this.sprintArray = resp.data.data;
          this.IsDesignSprintDone();
          this.setDesignSprintDays(resp.data.data);
        })
        .catch((err) => {
          console.log(err, "design sprint");
        });
    },
    IsDesignSprintDone() {
      // last day of design sprint
      // this.sprintArray[4].date
      if (this.sprintArray.length > 0) {
        let currentDFate = this.sprintArray[4].date;
        return this.$moment(currentDFate).isBefore(this.$moment());
      }
    },
    async drawRoadMap() {
      let project_id = this.$route.params.id;
      if (this.project.workspace_progress_level < 4) {
        this.spinner = true;
        await startRoadMap(project_id)
          .then((resp) => {
            this.spinner = false;
            this.$router.push({
              name: "roadmap",
              params: { id: project_id },
            });
          })
          .catch((error) => {
            this.spinner = false;
          });
      } else {
        this.$router.push({
          name: "roadmap",
          params: { id: project_id },
        });
      }
    },
    updateSprintArray(payload) {
      this.sprintArray = payload;
    },
    closeDialog() {
      this.editSprint = false;
    },
    handleAcceptSLA() {
      this.spinner = true;
      let project_id = this.$route.params.id;
      let data = { project_id };
      teamAcceptSLA(data)
        .then((resp) => {
          this.spinner = false;
          this.setSuccessMsg("SLA Signed!!");
          this.updateSelectedProject(resp.data.data);
        })
        .catch((error) => {
          this.spinner = false;
        });
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProject"]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    project() {
      return this.selectedProject;
    },
  },
};
</script>
<style scoped>
.prekickoff-wrapper {
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
  display: flex;
}
.right-container {
  flex-grow: 1;
}
.btn-wrap {
  padding-top: 9px;
  display: flex;
}
</style>
