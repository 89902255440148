<template>
  <div>
    <div class="page-layout">
      <div class="container-title">Generate Design Sprint schedule</div>
      <div class="description">
        Select the date you have agreed to start design sprint and click to
        generate schedule.
      </div>
      <div class="empty-box">
        <div><img src="/img/onboarding/schedule-icon.svg" /></div>
        <div class="waiting-msg">
          Waiting for Product owner to pay for Design Sprint...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
.page-layout {
  text-align: left;
}
.container-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.description {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  width: 512px;
  margin-top: 8px;
}
.empty-box {
  margin-top: 24px;
  width: 521px;
  height: 193px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: column;
}
.waiting-msg {
  margin-top: 16px;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #979da5;
  width: 278px;
}
</style>
