var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [_vm._v("Edit Schedule")]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "dialog-content" },
        _vm._l(_vm.form, function (sprint, k) {
          return _c("div", { key: k, staticClass: "form-grouped" }, [
            _c(
              "div",
              { staticClass: "form-container" },
              [
                _c("div", { staticClass: "div-label" }, [
                  _vm._v("Day " + _vm._s(sprint.day)),
                ]),
                _c(
                  "v-menu",
                  {
                    ref: "element" + k,
                    refInFor: true,
                    attrs: {
                      "close-on-content-click": false,
                      "return-value": _vm.form[k]["date"],
                    },
                    on: {
                      "update:returnValue": function ($event) {
                        return _vm.$set(_vm.form[k], "date", $event)
                      },
                      "update:return-value": function ($event) {
                        return _vm.$set(_vm.form[k], "date", $event)
                      },
                    },
                    model: {
                      value: _vm.form[k]["dateMenu"],
                      callback: function ($$v) {
                        _vm.$set(_vm.form[k], "dateMenu", $$v)
                      },
                      expression: "form[k]['dateMenu']",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "input_wrap",
                        attrs: { slot: "activator" },
                        slot: "activator",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form[k]["date"],
                              expression: "form[k]['date']",
                            },
                          ],
                          staticClass: "date_textbox",
                          attrs: {
                            type: "text",
                            placeholder: k == 0 ? "Select date" : "<autofill>",
                          },
                          domProps: { value: _vm.form[k]["date"] },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form[k],
                                  "date",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                _vm.form_errors[k]["date"] = ""
                              },
                            ],
                          },
                        }),
                        _c("img", {
                          attrs: { src: "/img/dashboard/calendar-icon.svg" },
                        }),
                      ]
                    ),
                    _c(
                      "v-date-picker",
                      {
                        attrs: {
                          "no-title": "",
                          scrollable: "",
                          min: _vm.todayDate,
                          type: "date",
                          "disabled-dates": _vm.disabledDates,
                        },
                        on: {
                          "update:picker-date": function ($event) {
                            return _vm.pickerUpdate($event)
                          },
                        },
                        model: {
                          value: _vm.form[k]["date"],
                          callback: function ($$v) {
                            _vm.$set(_vm.form[k], "date", $$v)
                          },
                          expression: "form[k]['date']",
                        },
                      },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.form[k]["dateMenu"] = false
                              },
                            },
                          },
                          [_vm._v("\n                Cancel\n              ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.$refs["element" + k][0].save(
                                  _vm.form[k]["date"]
                                )
                              },
                            },
                          },
                          [_vm._v("\n                Ok\n              ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-container" },
              [
                _c("div", { staticStyle: { height: "19px" } }),
                sprint.is_all_day == 0
                  ? _c(
                      "v-menu",
                      {
                        ref: "time" + k,
                        refInFor: true,
                        attrs: {
                          "close-on-content-click": false,
                          "return-value": _vm.form[k]["start_time"],
                        },
                        on: {
                          "update:returnValue": function ($event) {
                            return _vm.$set(_vm.form[k], "start_time", $event)
                          },
                          "update:return-value": function ($event) {
                            return _vm.$set(_vm.form[k], "start_time", $event)
                          },
                        },
                        model: {
                          value: _vm.form[k]["timeMenu"],
                          callback: function ($$v) {
                            _vm.$set(_vm.form[k], "timeMenu", $$v)
                          },
                          expression: "form[k]['timeMenu']",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "input_wrap",
                            attrs: { slot: "activator" },
                            slot: "activator",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form[k]["start_time"],
                                  expression: "form[k]['start_time']",
                                },
                              ],
                              staticClass: "date_textbox",
                              attrs: {
                                type: "text",
                                placeholder:
                                  k == 0 ? "Select time" : "<autofill>",
                              },
                              domProps: { value: _vm.form[k]["start_time"] },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form[k],
                                      "start_time",
                                      $event.target.value
                                    )
                                  },
                                  function ($event) {
                                    _vm.form_errors[k]["start_time"] = ""
                                  },
                                ],
                              },
                            }),
                            _c("img", {
                              attrs: { src: "/img/dashboard/time-icon.svg" },
                            }),
                          ]
                        ),
                        _c(
                          "v-time-picker",
                          {
                            attrs: {
                              landscape: true,
                              "ampm-in-title": true,
                              format: "ampm",
                            },
                            model: {
                              value: _vm.form[k]["start_time"],
                              callback: function ($$v) {
                                _vm.$set(_vm.form[k], "start_time", $$v)
                              },
                              expression: "form[k]['start_time']",
                            },
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.form[k]["timeMenu"] = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                Cancel\n              "
                                ),
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.$refs["time" + k][0].save(
                                      _vm.form[k]["start_time"]
                                    )
                                  },
                                },
                              },
                              [_vm._v("\n                Ok\n              ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "input_wrap",
                        staticStyle: { background: "#f7f7f8" },
                      },
                      [
                        _c("input", {
                          staticClass: "date_textbox",
                          attrs: {
                            type: "text",
                            placeholder: "All day",
                            disabled: "disabled",
                          },
                        }),
                        _c("img", {
                          attrs: { src: "/img/dashboard/time-icon.svg" },
                        }),
                      ]
                    ),
              ],
              1
            ),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          {
            staticClass: "grupa-grey-btn",
            staticStyle: { "margin-right": "24px" },
          },
          [_vm._v("\n        Cancel\n      ")]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-blue-btn",
            on: { click: _vm.handleEditDesignSprint },
          },
          [
            _vm._v("\n        Update Schedule\n        "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editSpinner,
                  expression: "editSpinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }