<template>
  <div>
    <Toolbar />
    <div class="pm-workspace">
      <div class="pm-container">
        <div class="progress-report-wrapper">
          <ProjectReport />
          <div class="project-activity">
            <ActivityLog v-bind="{ projectActivity, teamMembers }" />
          </div>
        </div>
        <SprintOverview />
        <div class="container-divider"></div>
        <!-- <SprintReport /> -->
        <ProjectDetails />
      </div>
    </div>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Toolbar from "@/components/toolbar/devProjectToolbar";
import ActivityLog from "@/components/workspace/activityLog";
// import SprintReport from "@/components/workspace/sprintReport";
import ProjectDetails from "@/components/workspace/projectDetails";
import ProjectReport from "@/components/workspace/projectReport";
import SprintOverview from "./sprintOverview";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
export default {
  props: {},
  components: {
    Toolbar,
    SprintOverview,
    ActivityLog,
    ProjectDetails,
    ProjectReport,
    AlertSuccess,
    AlertError,
  },

  data: () => ({}),

  created() {},
  methods: {},
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("devDashboard", [
      "projectActivity",
      "teamInfo",
      "selectedProjectDoc",
      "selectedProject",
    ]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
</style>
