var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "route-navbar" },
      [
        _c("Toolbar", [
          _c("div", { staticClass: "btn-wrap" }, [
            _vm.project.workspace_progress_level == 3 &&
            _vm.sprintArray.length == 0
              ? _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: { click: _vm.scheduleDesignSprintDays },
                  },
                  [
                    _vm._v("\n          Generate Schedule\n          "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.spinner,
                          expression: "spinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.project.workspace_progress_level == 3 &&
            _vm.sprintArray.length > 0 &&
            !_vm.isDesignOver
              ? _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: {
                      click: function ($event) {
                        _vm.editSprint = true
                      },
                    },
                  },
                  [_vm._v("\n          Edit Schedule\n        ")]
                )
              : _vm._e(),
            _vm.project.workspace_progress_level >= 3 &&
            !_vm.project.share_roadmap
              ? _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: { click: _vm.drawRoadMap },
                  },
                  [
                    _vm._v("\n          Draw Roadmap\n          "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.spinner,
                          expression: "spinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.project.share_roadmap && !_vm.project.accept_roadmap
              ? _c("button", { staticClass: "grupa-disabled-btn" }, [
                  _vm._v("\n          Awaiting Approval\n        "),
                ])
              : _vm._e(),
            _vm.project.workspace_progress_level >= 5 &&
            !_vm.project.team_accept_sla &&
            _vm.project.accept_roadmap
              ? _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: { click: _vm.handleAcceptSLA },
                  },
                  [
                    _vm._v("\n          Agree & Execute\n          "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.spinner,
                          expression: "spinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "prekickoff-wrapper" },
      [
        _c("ProgessBar"),
        _c(
          "div",
          { staticClass: "right-container" },
          [
            _vm.project.workspace_progress_level == 2 ||
            (_vm.project.workspace_progress_level == 3 &&
              _vm.project.is_dev_has_rate_meeting == 0)
              ? _c("MeetTheClient")
              : _vm._e(),
            _vm.project.workspace_progress_level <= 2 &&
            _vm.project.is_dev_has_rate_meeting == 1
              ? _c("WaitForSprintPayment")
              : _vm._e(),
            _vm.project.workspace_progress_level == 3 &&
            _vm.sprintArray.length > 0 &&
            !_vm.isDesignOver
              ? _c(
                  "DesignSprint",
                  _vm._b(
                    {},
                    "DesignSprint",
                    {
                      sprintArray: _vm.sprintArray,
                      updateSprintArray: _vm.updateSprintArray,
                    },
                    false
                  )
                )
              : _vm._e(),
            _vm.editSprint
              ? _c(
                  "EditDesignSprintSchedule",
                  _vm._b(
                    {},
                    "EditDesignSprintSchedule",
                    {
                      closeDialog: _vm.closeDialog,
                      updateDesignSprintSchedule:
                        _vm.updateDesignSprintSchedule,
                      editSpinner: _vm.editSpinner,
                    },
                    false
                  )
                )
              : _vm._e(),
            _vm.project.workspace_progress_level == 5 &&
            !_vm.project.accept_roadmap
              ? _c("DrawRoadMap")
              : _vm._e(),
            _vm.project.workspace_progress_level >= 5 &&
            !_vm.project.team_accept_sla &&
            _vm.project.accept_roadmap
              ? _c("SLADOC")
              : _vm._e(),
            _vm.project.workspace_progress_level == 6 &&
            _vm.project.team_accept_sla &&
            _vm.project.accept_roadmap
              ? _c("StartProductDevelopment")
              : _vm._e(),
            _vm.successMsg
              ? _c("AlertSuccess", { attrs: { time: "7" } })
              : _vm._e(),
            _vm.errorMsg
              ? _c("AlertError", { attrs: { time: "7" } })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }