var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "move-task-div" },
    [
      _c("div", { staticClass: "move-task-header" }, [
        _vm._v("\n    Move Task\n    "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.spinner,
              expression: "spinner",
            },
          ],
          staticClass: "button-spinner",
          staticStyle: { float: "right" },
          attrs: { src: "/img/lightbox/preloader.gif" },
        }),
      ]),
      _vm._l(_vm.taskProgress, function (stage, j) {
        return stage.task_status !== _vm.currentStage
          ? _c(
              "div",
              {
                key: j,
                staticClass: "task-single-stage",
                on: {
                  mouseover: function ($event) {
                    _vm.selected = true
                    _vm.currentHover = stage.task_status
                  },
                  mouseleave: function ($event) {
                    _vm.selected = false
                    _vm.currentHover = ""
                  },
                  click: function ($event) {
                    return _vm.handleMoveTask(stage.task_status)
                  },
                },
              },
              [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("div", { staticClass: "medium-icon" }, [
                    _c("img", { attrs: { src: _vm.imgLink + stage.img } }),
                  ]),
                  _c("div", { staticClass: "stage-text-span" }, [
                    _vm._v(_vm._s(stage.name)),
                  ]),
                ]),
                _vm.selected && _vm.currentHover == stage.task_status
                  ? _c("div", [
                      _c("img", {
                        attrs: { src: "/img/dashboard/task/checkmark.svg" },
                      }),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }