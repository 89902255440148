var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "link-url-div" }, [
          _vm._v(
            "\n        Add link to completed task e.g. link to design files, host url, link\n        to document etc.\n      "
          ),
        ]),
        _c("div", { staticClass: "completed-task" }, [
          _vm._v("\n        " + _vm._s(_vm.currentTask.task) + "\n      "),
        ]),
      ]),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          {
            staticClass: "grupa-grey-btn",
            staticStyle: { "margin-right": "24px", color: "#53585f" },
            on: { click: _vm.closeDialog },
          },
          [_vm._v("\n        No, Go back\n      ")]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-success-btn",
            on: { click: _vm.markTaskAsCompleted },
          },
          [
            _vm._v("\n        Mark as Complete\n        "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.spinner,
                  expression: "spinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dialog-title" }, [
      _vm._v("\n        Mark Task as Complete - "),
      _c("span", { staticStyle: { color: "#979da5" } }, [_vm._v("For Review")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }