<template>
  <div>
    <div class="page-layout">
      <div class="section-wrap">
        <div class="container-title">Generate Design Sprint schedule</div>
        <div class="schedule-msg">
          Select the date you have agreed to start design sprint and click to
          generate schedules
        </div>
        <div class="schedule-box">
          <div class="sprint-tips">
            We generally advice that you start on a Monday
          </div>
          <div class="form-grouped" v-for="(sprint, k) in form" :key="k">
            <div class="form-container">
              <div class="div-label">Day {{ k + 1 }}</div>
              <!-- v-menu date-picker -->
              <v-menu
                :ref="'element' + k"
                v-model="form[k]['dateMenu']"
                :close-on-content-click="false"
                :return-value.sync="form[k]['date']"
              >
                <div class="input_wrap" slot="activator">
                  <input
                    type="text"
                    v-model="form[k]['date']"
                    class="date_textbox"
                    :placeholder="k == 0 ? 'Select date' : '<autofill>'"
                    @input="form_errors[k]['date'] = ''"
                  />
                  <img src="/img/dashboard/calendar-icon.svg" />
                </div>
                <v-date-picker
                  v-model="form[k]['date']"
                  no-title
                  scrollable
                  :min="setMiniDate(k)"
                  type="date"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="form[k]['dateMenu'] = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['element' + k][0].save(form[k]['date'])"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
            <div class="form-container">
              <div style="height: 19px">
                <div v-if="k == 0" class="div-label" style="text-align: right">
                  <span
                    style="margin-right: 6px"
                    @click="repeat_time = !repeat_time"
                  >
                    <img v-show="repeat_time" src="/img/onboarding/check.svg" />
                    <img
                      v-show="!repeat_time"
                      src="/img/onboarding/uncheck.svg"
                    />
                  </span>
                  Same time everyday
                </div>
              </div>
              <!-- v-menu time-picker -->
              <v-menu
                v-if="k !== 3"
                :ref="'time' + k"
                v-model="form[k]['timeMenu']"
                :close-on-content-click="false"
                :return-value.sync="form[k]['start_time']"
              >
                <div class="input_wrap" slot="activator">
                  <input
                    type="text"
                    v-model="form[k]['start_time']"
                    class="date_textbox"
                    :placeholder="k == 0 ? 'Select time' : '<autofill>'"
                    @input="form_errors[k]['start_time'] = ''"
                  />
                  <img src="/img/dashboard/time-icon.svg" />
                </div>
                <v-time-picker
                  v-model="form[k]['start_time']"
                  :landscape="true"
                  :ampm-in-title="true"
                  format="ampm"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="form[k]['timeMenu'] = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs['time' + k][0].save(form[k]['start_time'])"
                  >
                    Ok
                  </v-btn>
                </v-time-picker>
              </v-menu>
              <div v-else class="input_wrap" style="background: #f7f7f8">
                <input
                  type="text"
                  class="date_textbox"
                  placeholder="All day"
                  disabled="disabled"
                />
                <img src="/img/dashboard/time-icon.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>
<script>
import AlertError from "@/components/alertError";
import { mapState, mapActions } from "vuex";
export default {
  props: {
    scheduleDesignSprintDays: Function,
  },
  components: {
    AlertError,
  },
  data: () => ({
    todayDate: new Date().toISOString().slice(0, 10),
    repeat_time: false,
    form_errors: [
      { date: "", start_time: "" },
      { date: "", start_time: "" },
      { date: "", start_time: "" },
      { date: "", start_time: "" },
      { date: "", start_time: "" },
    ],
    form: [
      {
        day: 1,
        date: "",
        start_time: "",
        dateMenu: false,
        timeMenu: false,
        is_all_day: 0,
      },
      {
        day: 2,
        date: "",
        start_time: "",
        dateMenu: false,
        timeMenu: false,
        is_all_day: 0,
      },
      {
        day: 3,
        date: "",
        start_time: "",
        dateMenu: false,
        timeMenu: false,
        is_all_day: 0,
      },
      {
        day: 4,
        date: "",
        start_time: "all",
        dateMenu: false,
        timeMenu: false,
        is_all_day: 1,
      },
      {
        day: 5,
        date: "",
        start_time: "",
        dateMenu: false,
        timeMenu: false,
        is_all_day: 0,
      },
    ],
  }),
  watch: {
    watchFirstTime: function (newVal) {
      if (this.repeat_time) {
        this.autoFillTime(newVal);
      }
    },
    watchFirstDate: function (newVal) {
      this.autoFillDays(newVal, 1);
    },
    watchSecondDate: function (newVal) {
      this.autoFillDays(newVal, 2);
    },
    watchThirdDate: function (newVal) {
      this.autoFillDays(newVal, 3);
    },
    watchForthDate: function (newVal) {
      this.autoFillDays(newVal, 4);
    },
    watchFifthDate: function (newVal) {
      this.autoFillDays(newVal, 5);
    },
    repeat_time: function (newVal) {
      if (this.form[0].start_time !== "" && newVal) {
        this.autoFillTime(this.form[0].start_time);
      } else if (!newVal) {
        this.autoFillTime("");
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("alertMsg", ["setErrorMsg"]),
    setMiniDate(days) {
      let todayDate = new Date().toISOString().slice(0, 10);
      let result = this.$moment(todayDate, "YYYY-MM-DD").add(days, "days");
      return result.format("YYYY-MM-DD");
    },
    handleDesignSprint() {
      let designSprintForm = this.form;
      let ErrorForm = [];
      // Validate textboxes
      for (let k = 0; k < designSprintForm.length; k++) {
        if (
          designSprintForm[k].date == "" ||
          designSprintForm[k].start_time == ""
        ) {
          ErrorForm.push(k);
        }
      }
      if (ErrorForm.length > 0) {
        this.setErrorMsg("Kindly fill all Design Sprint dates and start time");
        return null;
      } else if (ErrorForm.length == 0) {
        let formObj = {
          project_id: this.$route.params.id,
          design_sprints: this.form,
        };
        return formObj;
      }
    },
    autoFillDays(selectedDate, selectedDay) {
      const day = this.$moment(selectedDate).isoWeekday();
      if (day > 5) {
        this.setErrorMsg(
          "Design sprint days must fall between Monday and Friday"
        );
        this.form[0].date = "";
      } else if (day <= 5 && selectedDay == 1) {
        // For changes on textbox Day 1
        this.form.forEach((date, i) => {
          if (i > 0) {
            let previousDate = this.form[i - 1].date;
            if (this.$moment(previousDate).isoWeekday() == 5) {
              // Skip weekends, add 3 days
              let new_date = this.$moment(previousDate, "YYYY-MM-DD").add(
                3,
                "days"
              );
              date.date = new_date.format("YYYY-MM-DD");
            } else if (this.$moment(previousDate).isoWeekday() < 5) {
              // add 1 day
              let new_date = this.$moment(previousDate, "YYYY-MM-DD").add(
                1,
                "days"
              );
              date.date = new_date.format("YYYY-MM-DD");
            }
          }
        });
      } else if (day <= 5 && selectedDay > 1) {
        // For changes on other textboxes Day 2 to Day 5
        let textBoxIndex = this.form[selectedDay - 1].date;
        this.form.forEach((date, i) => {
          if (date.day > selectedDay) {
            // Go forward, set date for textboxes above current
            if (this.$moment(textBoxIndex).isoWeekday() == 5) {
              // Skip weekends, add 3 days
              let new_date = this.$moment(textBoxIndex, "YYYY-MM-DD").add(
                3,
                "days"
              );
              date.date = new_date.format("YYYY-MM-DD");
            } else if (this.$moment(textBoxIndex).isoWeekday() < 5) {
              // add 1 day
              let new_date = this.$moment(textBoxIndex, "YYYY-MM-DD").add(
                1,
                "days"
              );
              date.date = new_date.format("YYYY-MM-DD");
            }
          } else if (date.day < selectedDay) {
            // Go backward, set date if not empty for textboxes below current
            if (date.date == "") {
              // remove 1 days
              let new_date = this.$moment(textBoxIndex, "YYYY-MM-DD").subtract(
                1,
                "days"
              );
              date.date = new_date.format("YYYY-MM-DD");
            }
          }
        });
      }
    },
    autoFillTime(selectedTime) {
      this.form.forEach((time, i) => {
        if (i > 0 && selectedTime) {
          time.start_time = selectedTime;
        } else if (i > 0 && selectedTime == "") {
          time.start_time = "";
        }
      });
    },
  },
  computed: {
    ...mapState("alertMsg", ["errorMsg"]),
    watchFirstDate() {
      return this.form[0].date;
    },
    watchSecondDate() {
      return this.form[1].date;
    },
    watchThirdDate() {
      return this.form[2].date;
    },
    watchForthDate() {
      return this.form[3].date;
    },
    watchFifthDate() {
      return this.form[4].date;
    },
    watchFirstTime() {
      return this.form[0].start_time;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.section-wrap {
  text-align: left;
  width: 521px;
}
.container-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.schedule-msg {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 8px;
}
.schedule-box {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  padding: 24px 24px 40px;
}
.sprint-tips {
  font-size: 14px;
  line-height: 130%;
  color: #000000;
}
.form-grouped {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}
.form-control {
  margin-top: 7px;
  margin-bottom: 0px;
  width: 225px;
  height: 48px;
}
.div-label {
  font-size: 14px;
  line-height: 130%;
  color: #000000;
  text-align: left;
}
.date_textbox {
  width: 84%;
}
.input_wrap {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 0px;
  display: flex;
  padding: 15px 0px 12px 16px;
  height: 48px;
  width: 225px;
}
.date_textbox:focus {
  border: 0px;
  outline: none;
}
</style>
