<template>
  <div class="page-layout">
    <div class="page-wrap-container">
      <div class="start-prod-dev">Start Product Development</div>
      <div class="text-msg-below">
        You will be notified once the Product Owner selects and commits to a
        kickoff date.
      </div>
      <div class="waiting-div-wrap">
        <div class="waiting-client">
          <img src="/img/onboarding/schedule-icon.svg" />
          <div class="center-text-div">
            Waiting for the Product Owner to pay and commit to a kickoff date...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-wrap-container {
  text-align: left;
  width: 521px;
}
.start-prod-dev {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.text-msg-below {
  margin-top: 16px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.waiting-div-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 263px;
  width: 521px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
}
.waiting-client {
  text-align: center;
}
.center-text-div {
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #979da5;
  width: 278px;
  margin-top: 16px;
}
</style>
