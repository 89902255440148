var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.project == null
        ? _c("Loader")
        : _c(
            "div",
            [
              _vm.project.workspace_progress_level < 7
                ? _c("Prekickoff")
                : _vm._e(),
              _vm.project.workspace_progress_level >= 7
                ? _c("div", [_c("EngineerWorkSpace")], 1)
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }