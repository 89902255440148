var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-details-conatiner" }, [
    _c("div", { staticClass: "contain-title" }, [_vm._v("PRODUCT DETAILS")]),
    _c("div", { staticClass: "project-info-wrapper" }, [
      _c("div", { staticClass: "project-main-info" }, [
        _c("div", { staticClass: "product-name" }, [
          _vm._v(_vm._s(_vm.project.project_name)),
        ]),
        _c("div", { staticClass: "product-description" }, [
          _vm._v("\n        " + _vm._s(_vm.project.overview) + "\n      "),
        ]),
        _c("div", { staticClass: "product-industry" }, [
          _c("div", [
            _c("img", {
              attrs: {
                src:
                  "/img/onboarding/platforms/" +
                  _vm.replaceUnderscore(_vm.project.project_type) +
                  ".svg",
                alt: "",
              },
            }),
          ]),
          _c("div", [
            _c("img", {
              attrs: {
                src:
                  "/image/clientProjectCategories/" +
                  _vm.project.category.toLowerCase() +
                  ".svg",
                alt: "",
              },
            }),
          ]),
          _c("div", [
            _c("img", { attrs: { src: _vm.imgLink + "platform3.svg" } }),
          ]),
        ]),
        _vm.project.owner
          ? _c("div", { staticClass: "project-owner" }, [
              _c("div", { staticClass: "name-short" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.abbrevateName(_vm.project.owner.name).toUpperCase()
                    ) +
                    "\n        "
                ),
              ]),
              _c("div", { staticStyle: { "margin-left": "8px" } }, [
                _c("div", { staticClass: "info-name" }, [
                  _vm._v(_vm._s(_vm.project.owner.name)),
                ]),
                _c("div", { staticClass: "info-rate" }, [_vm._v("Unrated")]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "middle-container" }, [
        _c("div", { staticClass: "left-div" }, [
          _c("div", { staticClass: "grey-header" }, [_vm._v("PReferred tech")]),
          _c("div", { staticClass: "no-option" }, [_vm._v("No Preference")]),
          _c("div", { staticClass: "similar-site" }, [_vm._v("similar sites")]),
          _vm.project.similar_projects
            ? _c(
                "div",
                { staticClass: "all-site", staticStyle: { width: "189px" } },
                _vm._l(
                  _vm.parseToJson(_vm.project.similar_projects),
                  function (site, i) {
                    return _c("div", { key: i, staticClass: "div-site" }, [
                      _vm._v("\n            " + _vm._s(site) + "\n          "),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "right-div" }, [
          _c("div", { staticClass: "grey-header" }, [_vm._v("service plan")]),
          _c("div", { staticClass: "plan-info" }, [
            _vm._m(0),
            _c("div", { staticStyle: { "margin-left": "4px" } }, [
              _c("div", { staticClass: "info-name" }, [
                _vm._v(_vm._s(_vm.project.experience)),
              ]),
              _c("div", { staticClass: "info-rate" }, [
                _vm._v(
                  "\n              $" +
                    _vm._s(
                      (
                        _vm.project.price / _vm.project.iteration_cycle
                      ).toLocaleString()
                    ) +
                    "/weekly sprint\n            "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "team-developers" },
        _vm._l(_vm.teamMembers, function (member, i) {
          return member.name
            ? _c("div", { key: i, staticClass: "members-box" }, [
                _c(
                  "div",
                  { staticClass: "profile-image" },
                  [
                    _c("v-avatar", {
                      staticClass: "profile_bg",
                      style: {
                        backgroundImage:
                          "url(" +
                          (_vm.$imageUrl + member.profile_picture || _vm.test) +
                          ")",
                      },
                      attrs: { size: 24, color: "grey lighten-4" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "dev-details" }, [
                  _c("div", { staticClass: "dev-name" }, [
                    _vm._v(_vm._s(_vm.shortenName(member.name))),
                  ]),
                  _c("div", { staticClass: "dev-role" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(member.team_lead == 1 ? "Team Lead/" : "") +
                        "\n            " +
                        _vm._s(member.team_role.split(" ")[0]) +
                        "\n          "
                    ),
                  ]),
                ]),
              ])
            : _vm._e()
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "plan-icon", staticStyle: { "margin-top": "4px" } },
      [_c("img", { attrs: { src: "/img/onboarding/upstart-icon.svg" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }